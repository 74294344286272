import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export default function useScreenHeight() {
  const { pathname } = useLocation();
  const [scrollBottom, setScrollBottom] = useState(false);

  const goBottom = (e) => {
    e.preventDefault();
    window.scrollTo({
      top: document.body.offsetHeight,
      left: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    let cleanupFunction = false;
    function handleHeightChange() {
      const { innerHeight } = window;
      const documentScrollHeight = Math.max(
        document.body.scrollHeight, document.documentElement.scrollHeight,
        document.body.offsetHeight, document.documentElement.offsetHeight,
        document.body.clientHeight, document.documentElement.clientHeight,
      );
      const { pageYOffset } = window;
      const offset = (documentScrollHeight - innerHeight) * 0.95;

      if (offset <= 0 || offset <= pageYOffset) {
        setScrollBottom(false);
      }

      if (offset > pageYOffset) {
        setScrollBottom(true);
      }
    }

    if (!cleanupFunction) {
      window.addEventListener('scroll', handleHeightChange);
      handleHeightChange();
    }

    return () => {
      cleanupFunction = true;
      window.removeEventListener('scroll', handleHeightChange);
    };
  }, [pathname]);

  return { scrollBottom, goBottom };
}
