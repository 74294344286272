import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  LoginPage,
  PasswordResetPage,
  ResetPasswordConfirmPage,
  NotFoundPage,
  ForbiddenPage,
  QuizPage,
  SupportPage,
  ProfilePage,
  CheckInPage,
  EditCheckin,
  CreateCheckin,
  WelcomePage,
  InternalServerError,
  FeedBackPage,
} from './pages';
import { ProtectedRoute, BottomNavigation } from './components';
import { Routes } from './routes/routes';
import 'antd/dist/antd.css';
import './app.less';
import OrientationNotAvailable from './components/OrientationNotAvailable/OrientationNotAvailable';
import { isPlatform } from './utils/helpers';
import HeaderInfo from './components/HeaderInfo';
import { headerInfoData } from './utils/vars';

const App = () => {
  const isAuth = useSelector((state) => state.auth.isAuth);
  const isMacOS = isPlatform(['MAC', 'IPHONE', 'IPAD']);

  return (
    <>
      {
          headerInfoData.some(({ value }) => !!value)
            ? <HeaderInfo headerInfoData={headerInfoData} /> : null
      }
      <Switch>
        <ProtectedRoute path={Routes.WELCOME} component={WelcomePage} exact />
        <ProtectedRoute path={Routes.QUIZ} component={QuizPage} exact />
        <ProtectedRoute path={Routes.SUPPORT} component={SupportPage} exact />
        <ProtectedRoute path={Routes.PROFILE} component={ProfilePage} exact />
        <ProtectedRoute path={Routes.CHECK_INS} component={CheckInPage} exact />
        <ProtectedRoute path={Routes.CHECK_IN_ADD} component={CreateCheckin} exact />
        <ProtectedRoute path={Routes.CHECK_IN_ID} component={EditCheckin} exact />
        <ProtectedRoute path={Routes.FEEDBACK} component={FeedBackPage} exact />
        <Route path={Routes.LOGIN} component={LoginPage} />
        <Route path={Routes.RESET_PASSWORD} component={PasswordResetPage} exact />
        <Route path={Routes.RESET_PASSWORD_CONFIRM} component={ResetPasswordConfirmPage} />
        <Route path={Routes.FORBIDDEN} component={ForbiddenPage} />
        <Route path={Routes.SERVERERROR} component={InternalServerError} />
        <Redirect exact from="/" to="/checkins" />
        <Route component={NotFoundPage} />
      </Switch>
      {isAuth ? <BottomNavigation /> : null}
      { isMacOS || !window.matchMedia('(display-mode: standalone)').matches ? <OrientationNotAvailable /> : null }
    </>
  );
};

export default App;
