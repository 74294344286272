import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import API from '../../services/apiService';
import { ProfilePhoto } from './ProfilePhoto';

const ProfilePageUserImg = ({
  lastName, firstName, isShowEdit, src,
  image, setImage, newImage,
  imageId, setImageId, showRemoveModal,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!src) {
      setImage(null);
      return setIsLoading(false);
    }

    (async () => {
      if (!image || imageId !== src) {
        try {
          const { data } = await API.get(`/aws/${src}`);
          if (data.statusCode === 200) {
            setImage(data.payload);
            setImageId(src);
            return setIsLoading(false);
          }
        } catch (e) {
          return setIsLoading(false);
        }
      }
      return setIsLoading(false);
    })();
    return () => {};
  }, []);

  return (
    <ProfilePhoto
      src={src}
      isLoadingSkeleton={isLoading}
      image={image}
      isShowEdit={isShowEdit}
      newImage={newImage}
      firstName={firstName}
      lastName={lastName}
      onRemovePhoto={showRemoveModal}
    />
  );
};

ProfilePageUserImg.propTypes = {
  isShowEdit: PropTypes.bool.isRequired,
  lastName: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  src: PropTypes.oneOfType(
    [PropTypes.oneOf([null]), PropTypes.string],
  ),
  image: PropTypes.string,
  setImage: PropTypes.func.isRequired,
  newImage: PropTypes.string,
  imageId: PropTypes.string,
  setImageId: PropTypes.func,
  showRemoveModal: PropTypes.func,

};

export default ProfilePageUserImg;
