import axios from 'axios';
import { errorColor, notifyDialog } from '../utils/notify';
import db from '../indexedDB/db';

/**
 * Setting up the axios object
 */
const API = axios.create({
  baseURL: '/api/',
  responseType: 'json',
  headers: { cache: 'no-store' },
});

/**
 * Callback that is executed in case of a successful request
 */
const onFulfilled = (response) => response;

/**
 * Callback that is executed in case of an error
 */
const onRejected = async (error) => {
  // return validation errors for custom handling
  if (error.response && error.response.data && error.response.data.errors) {
    throw error.response.data.errors;
  }
  if (error.response?.status === 401) {
    localStorage.clear();
    // store.dispatch({ type: 'LOG OUT' });
    await db.loggedUser.clear();
    window.location.href = `${process.env.REACT_APP_PUBLIC_URL}/login`;
  } else if (error.response?.status === 403) {
    window.location.href = 'forbidden-page';
  } else if (error.response?.data.message
      && error.response?.data.statusCode < 500
      && error.response?.data.statusCode !== 404) {
    notifyDialog({
      title: error.response.data.message,
      titleColor: errorColor,
    });
  }

  return Promise.reject(error);
};

API.interceptors.response.use(onFulfilled, onRejected);

export default API;
