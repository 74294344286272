/* eslint-disable */
import React, { useState } from 'react';
import { Layout, Modal } from 'antd';
import { Form } from 'antd-mobile';
import PropTypes from 'prop-types';
import { CheckinList } from './CheckinList';
import './_checkinInfo.less';
import { CheckinInputItem } from './CheckinInputItem';
import { CheckinRateStars } from './CheckinRateStars';
import { CheckinCheckboxItem } from './CheckinCheckboxItem';
import { CheckinListUploader } from './CheckinListUploader';
import { CheckinImgUploader } from './CheckinImgUploader';
import { CheckinRateSmiles } from './CheckinRateSmiles';
import { CheckinInstruction } from './CheckinInstruction';
import { CheckInHelpArea } from './CheckInHelpArea';
import { CHECK_IN_HELP_TEXT } from '../../utils/vars';
import ImgComponent from '../../components/ImgComponent';
import withIsDisabled from '../../hocks/withIsDisabled';
import CheckinUploadMFP from './CheckinUploadMFP';
import BackPhotoIcon from '../../icon/BackPhotoIcon';
import FrontPhotoIcon from '../../icon/FrontPhotoIcon';
import SidePhotoIcon from '../../icon/SidePhotoIcon';
import API from '../../services/apiService';
import { errorColor, notifyDialog } from '../../utils/notify';
import { ProfilePageMyFitnessPalForm } from '../ProfilePage/ProfilePageMyFitnessPalForm';

const { Content } = Layout;

const CheckinForm = ({
  initialValues, data, onSubmit, Header, Footer, updateCheckin,
  notes,
  isDisabledFields = false,
  mfp,
}) => {
  const {
    checkinInputs, checkinRate, checkinSwitch, checkinFile, checkinRateEmoji, initialCsv,
  } = data;
  const getInitialImagesData = () => {
    if (Array.isArray(checkinFile)) {
      return checkinFile.reduce((obj, current) => ({ ...obj, [current.key]: current.value }), {});
    }
    return {};
  };

  const [imagesData, setImagesData] = useState(getInitialImagesData());
  const [isUpload, setIsUpload] = useState(false);
  const [svgData, setSvgData] = useState({ ...initialCsv });

  const getMfpCalories = async () => {
    setIsUpload(true);
    try {
      const response = await API.get('/mfp');
      const {
        averageCalories, averageCarbohydrates, averageFat, averageProtein,
      } = response.data.payload;
      setSvgData({
        averageCalories, averageCarbohydrates, averageFat, averageProtein,
      });
      setIsUpload(false);
    } catch (e) {
      setIsUpload(false);
      notifyDialog({ titleColor: errorColor, title: 'Something went wrong' });
    }
  };

  const setUpload = (flag) => setIsUpload(flag);
  const setImageId = (image) => setImagesData((state) => ({ ...state, ...image }));

  const getPhotoIcon = (name) => {
    switch (name) {
      case 'backPhotoId': {
        return BackPhotoIcon;
      }
      case 'frontPhotoId': {
        return FrontPhotoIcon;
      }
      case 'profilePhotoId': {
        return SidePhotoIcon;
      }
      default: {
        return null;
      }
    }
  };

  const onHandleSubmit = async (values) => {
    await onSubmit({ ...values });
  };

  const onFinish = async (values) => {
    await onHandleSubmit({ ...values, ...imagesData, ...svgData });
  };
  const ImgComponentWithIsDisabled = withIsDisabled(ImgComponent, !isDisabledFields);

  return (
    <Layout className="page__layout check-in-page">
      <Header disabled={isUpload} />
      <Content className="check-in-page-content page_content_with_bg">
        {
                    notes && (
                    <CheckinInstruction
                      title="Instructions"
                    >
                      {notes}
                    </CheckinInstruction>
                    )
                }
        <Form
          className="check-in-page-content-form"
          layout="horizontal"
          onFinish={onFinish}
          footer={Footer(isUpload)}
          initialValues={initialValues}
        >
          <CheckInHelpArea className="check-in-page-content-form">
            {CHECK_IN_HELP_TEXT}
          </CheckInHelpArea>
          <ImgComponent src="/assets/images/measurements-with-text.svg" alt="measurements" className="check-in-page-content-form-img" />
          <div className="check-in-page-content-form-item-wrapper">
            <CheckinList checkins={checkinInputs} ViewComponent={CheckinInputItem} />
          </div>
          <ImgComponent src="/assets/images/score-card-icon.svg" alt="core-card" className="check-in-page-content-form-img" />
          <div className="check-in-page-content-form-item-wrapper">
            <CheckinList checkins={checkinRate} ViewComponent={CheckinRateStars} />
            <CheckinList checkins={checkinRateEmoji} ViewComponent={CheckinRateSmiles} />
            <CheckinList checkins={checkinSwitch} ViewComponent={CheckinCheckboxItem} />
          </div>
          {/*<CheckinUploadMFP*/}
          {/*  getMfp={getMfpCalories}*/}
          {/*  isUpload={isUpload}*/}
          {/*  setSvgData={setSvgData}*/}
          {/*  setUpload={setUpload}*/}
          {/*  svgData={svgData}*/}
          {/*  isDisabledFields={isDisabledFields}*/}
          {/*/>*/}
          <ImgComponentWithIsDisabled src="/assets/images/upload-photo.svg" alt="upload-photo" className="check-in-page-content-form-img" />
          <CheckinListUploader
            checkins={checkinFile}
            ViewComponent={CheckinImgUploader}
            updateCheckin={updateCheckin}
            imagesData={imagesData}
            setId={setImageId}
            isUpload={isUpload}
            setUpload={setUpload}
            getPhotoIcon={getPhotoIcon}
          />
        </Form>
      </Content>
      {
       mfp.isShowMfp && (
       <Modal
         footer={null}
         closeIcon={null}
         closable={false}
         visible
       >
         <ProfilePageMyFitnessPalForm
           onFinish={mfp.onSubmitMfp}
           onCancel={() => mfp.setIsShowMfp(false)}
           isLoading={mfp.isLoadingMfp}
         />
       </Modal>
       )
      }
    </Layout>
  );
};

CheckinForm.propTypes = {
  mfp: PropTypes.shape({
    isShowMfp: PropTypes.bool,
    isLoadingMfp: PropTypes.bool,
    onSubmitMfp: PropTypes.func,
    setIsLoadingMfp: PropTypes.func,
    setIsShowMfp: PropTypes.func,
  }),
  initialValues: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
    PropTypes.bool.isRequired,
    PropTypes.oneOf([null]),
  ])).isRequired,
  notes: PropTypes.string,
  data: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ])).isRequired,
  onSubmit: PropTypes.func.isRequired,
  Header: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.elementType,
  ]).isRequired,
  Footer: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.elementType,
  ]).isRequired,
  updateCheckin: PropTypes.func,
  isDisabledFields: PropTypes.bool,
};

export default CheckinForm;
