import PropTypes from 'prop-types';

export const userPropTypes = {
  id: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  clientType: PropTypes.oneOfType([
    PropTypes.string,
  ]),
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  roles: PropTypes.string.isRequired,
  currentGoal: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
  disabled: PropTypes.bool.isRequired,
  startDate: PropTypes.string.isRequired,
  competing: PropTypes.bool.isRequired,
  physiqueGoal: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
  lastUpdated: PropTypes.string.isRequired,
  bio: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
  specificArea: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
  photo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
  showAsCoach: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.oneOf([null]),
  ]),
  fullName: PropTypes.string.isRequired,
};
