import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = ({ fill, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.5 56.5" {...props}>
    <defs>
      <style>{`.cls-1{fill:${fill}`}</style>
    </defs>
    <g id="Layer_2">
      <g id="Layer_1-2">
        <path
          className="cls-1"
          d="M28.25 0A28.25 28.25 0 1 0 56.5 28.25 28.28 28.28 0 0 0 28.25 0Zm24.57 28.25A24.57 24.57 0 1 1 28.25 3.68a24.6 24.6 0 0 1 24.57 24.57Z"
        />
        <path
          className="cls-1"
          d="M14 14.79a2.95 2.95 0 1 0 3 2.95 2.95 2.95 0 0 0-3-2.95ZM14 25.3a2.95 2.95 0 1 0 3 2.95 2.95 2.95 0 0 0-3-2.95ZM14 35.81a2.95 2.95 0 1 0 3 2.95 2.95 2.95 0 0 0-3-2.95ZM20.73 19.58h23.34a1.84 1.84 0 1 0 0-3.68H20.73a1.84 1.84 0 0 0 0 3.68ZM44.07 26.41H20.73a1.84 1.84 0 0 0 0 3.68h23.34a1.84 1.84 0 0 0 0-3.68ZM44.07 36.92H20.73a1.84 1.84 0 0 0 0 3.68h23.34a1.84 1.84 0 0 0 0-3.68Z"
        />
      </g>
    </g>
  </svg>
);

SvgComponent.propTypes = {
  fill: PropTypes.string,
};

export default SvgComponent;
