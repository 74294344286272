import React from 'react';
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';
import { compose } from '../../utils/helpers';
import withoutAuthBackground from '../../hocks/withoutAuthBackground';

function ForbiddenPage() {
  return (
    <Result
      status="403"
      title="403"
      subTitle="Forbidden. You dont have permission to access this page"
      extra={(
        <Button type="primary">
          <Link to="/checkins">Back Home</Link>
        </Button>
      )}
    />
  );
}

export default compose(withoutAuthBackground)(ForbiddenPage);
