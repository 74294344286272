import React from 'react';
import { compose } from '../../utils/helpers';
import withOnline from '../../hocks/withOnline';
import withSync from '../../hocks/withSync';

const SupportPage = () => (
  <div>
    <iframe className="support_page" src="https://sportsmodelproject.freshdesk.com/support/home" title="Support Page" style={{ width: '100%', height: '100vh' }} />
  </div>
);

export default compose(withOnline, withSync)(SupportPage);
