import React, { useEffect } from 'react';

const withScroll = (Component) => (props) => {
  useEffect(() => {
    document.body.parentElement.classList.add('overflow_hidden');
    return () => {
      document.body.parentElement.classList.remove('overflow_hidden');
    };
  });

  return <Component {...props} />;
};

export default withScroll;
