import React from 'react';
import { Form } from 'antd';
import { Button } from 'antd-mobile';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';
import { validationAntService } from '../../utils/validationAntService';
import 'react-phone-input-2/lib/high-res.css';
import withHideFooter from '../../hocks/withHideFooter';

const { validatePhoneNumber } = validationAntService();

const PhoneInputWithHideFooter = withHideFooter(PhoneInput);

const ProfilePageEditMobile = ({
  onCancel, onFinish, phoneNumber, isLoadingSubmit,
}) => (
  <Form
    onFinish={onFinish}
    initialValues={{
      phoneNumber,
    }}
    validateTrigger={['onBlur']}
    className="edit_profile_form"
  >
    <Form.Item
      name="phoneNumber"
      className="form_item_input_wrapper"
      rules={[
        {
          validator: validatePhoneNumber,
        },
      ]}
    >
      <PhoneInputWithHideFooter />
    </Form.Item>
    <Form.Item
      className="form_item_button_wrapper"
    >
      <Button
        className="form_button"
        onClick={onCancel}
      >
        Cancel
      </Button>
    </Form.Item>
    <Form.Item
      className="form_item_button_wrapper"
    >
      <Button
        loading={isLoadingSubmit}
        htmlType="submit"
        type="primary"
        className="form_button"
      >
        Save
      </Button>
    </Form.Item>
  </Form>
);

ProfilePageEditMobile.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  isLoadingSubmit: PropTypes.bool.isRequired,
};

export { ProfilePageEditMobile };
