import * as React from 'react';

function BackPhotoIcon(props) {
  return (
    <svg viewBox="0 0 50.29 47.92" width="1em" height="1em" {...props}>
      <defs>
        <style>{'.prefix__cls-1{fill:currentColor}'}</style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <g id="prefix__Layer_1-2" data-name="Layer 1">
          <path
            className="prefix__cls-1"
            d="M16.32 46.89a1.78 1.78 0 00.08-1.34 1.69 1.69 0 00-.89-1 21.63 21.63 0 1129-9.76 21.77 21.77 0 01-9.71 9.73 1.77 1.77 0 00-1.07 2.24A1.75 1.75 0 0036 47.82a1.27 1.27 0 00.33-.16A25.13 25.13 0 0047.66 14 25.13 25.13 0 0014 2.63 25.09 25.09 0 002.63 36.31 25.29 25.29 0 0014 47.66a1.74 1.74 0 002.32-.77z"
          />
          <path
            className="prefix__cls-1"
            d="M18.81 36.79a1.14 1.14 0 00-.15.18 1.71 1.71 0 00-.46 1.12 1.69 1.69 0 00.46 1.17l5.23 5.22a1.32 1.32 0 00.59.42 1.77 1.77 0 001.3 0 1.27 1.27 0 00.59-.4l5.2-5.19a1.78 1.78 0 000-2.46 1.74 1.74 0 00-2.45 0l-2.23 2.2v-16.5a1.75 1.75 0 10-3.49 0v16.57l-2.21-2.19a1.66 1.66 0 00-2.38-.14z"
          />
        </g>
      </g>
    </svg>
  );
}

const MemoBackPhotoIcon = React.memo(BackPhotoIcon);
export default MemoBackPhotoIcon;
