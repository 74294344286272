import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Layout } from 'antd';
import { Document, Page } from 'react-pdf';
import { compose } from '../../utils/helpers';
import withOnline from '../../hocks/withOnline';
import withSync from '../../hocks/withSync';
import './_welcomePage.less';
import withAuthBackground from '../../hocks/withAuthBackground';
// import withLoadingPdf from '../../hocks/withLoadingPdf';
import LoadingComponent from '../../components/LoadingComponent';
import API from '../../services/apiService';

const WelcomePage = () => {
  const clientType = useSelector((state) => state.auth.user.clientType);
  const [pdfPages, setPdfPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [pdf, setPdf] = useState(null);

  const onLoadDefault = () => {
    setPdf('./assets/pdf/smp-Elite.pdf');
  };

  const onLoadPdf = async () => {
    setIsLoading(true);
    try {
      const response = await API.get('/client-types/pdf', {
        params: {
          url: clientType.welcomePackUrl,
        },
        responseType: 'blob',
      });
      setPdf(response.data);
      setIsLoading(false);
    } catch (e) {
      onLoadDefault();
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onLoadPdf();
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setPdfPages(numPages);
  };

  if (isLoading) {
    return <LoadingComponent />;
  }

  return (
    <Layout className="welcome-layout">
      <div
        className="welcome-pdf"
      >
        <Document
          loading={<LoadingComponent />}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={onLoadDefault}
          file={pdf}
        >
          {Array.from(
            new Array(pdfPages),
            (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
              />
            ),
          )}
        </Document>
      </div>
    </Layout>
  );
};

export default compose(withAuthBackground, withOnline, withSync)(WelcomePage);
