import React from 'react';
import { Typography } from 'antd';
import PropTypes from 'prop-types';

const { Text, Title } = Typography;

const CheckinInstruction = ({ title, children }) => (
  <>
    <Title level={5}>{title}</Title>
    <Text>
      {children}
    </Text>
  </>
);

CheckinInstruction.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
};

export { CheckinInstruction };
