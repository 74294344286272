import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { FETCH_FEEDBACKS_DELAY } from '../utils/vars';
import { getUnreadCountsFeedback } from '../redux/asyncServices/feedBackService';
import { getUnreadCountFeedbacksSelector } from '../redux/selectors/feedBacksSelector';

const useUpdateFeedbacks = () => {
  const dispatch = useDispatch();
  const unreadCount = useSelector(getUnreadCountFeedbacksSelector);

  useEffect(() => {
    const updateReadCount = setInterval(() => {
      dispatch(getUnreadCountsFeedback());
    }, FETCH_FEEDBACKS_DELAY);

    return () => clearInterval(updateReadCount);
  }, []);

  return {
    unreadCount,
  };
};

export default useUpdateFeedbacks;
