import * as React from 'react';
import PropTypes from 'prop-types';

function PenIcon(props) {
  const { fill = '#ad964f' } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58.15 65.71" {...props}>
      <defs>
        <clipPath id="clip-path">
          <path
            style={{
              fill: 'none',
            }}
            d="M0 0h58.15v65.71H0z"
          />
        </clipPath>
        <style>{`.cls-3{fill:${fill}}`}</style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g
          style={{
            clipPath: 'url(#clip-path)',
          }}
          id="Layer_1-2"
          data-name="Layer 1"
        >
          <path
            className="cls-3"
            d="m22.68 52.05 5 1.61-5.29 8.12ZM36.09 7.53l2 .63-13.26 42.67-1.83-.52Zm1.12-3.76a3.12 3.12 0 0 1 1.47-1.68 2.82 2.82 0 0 1 2.17-.2 2.86 2.86 0 0 1 1.87 3.56l-.6 2-2.66-.79-2.85-.89ZM28.46 52l-2-.63L39.72 8.58l1.88.63Zm-6.34 13.27 7.61-11.89.06.06.07-.07L43.51 8.86 44.37 6a4.46 4.46 0 0 0-.28-3.49 4.62 4.62 0 0 0-2.09-2 .91.91 0 0 0-.53-.51.76.76 0 0 0-.61.1 4.43 4.43 0 0 0-2.96.5 4.34 4.34 0 0 0-2.25 2.74L35 5.42h-.07A4.7 4.7 0 0 0 30 8.62l-4.57 15.07a.73.73 0 0 0 0 .63 1 1 0 0 0 .58.51h.27a1.06 1.06 0 0 0 .88-.63l4.58-15a2.6 2.6 0 0 1 1.39-1.69 2.64 2.64 0 0 1 1.18-.29h.11L21 50.65v.27l-.47 13.92a1 1 0 0 0 .59.87h.28a.76.76 0 0 0 .68-.42ZM36.92 43.54h15A6.16 6.16 0 1 0 52 31.23H40.17a.88.88 0 1 0 0 1.75h11.76a4.41 4.41 0 0 1 0 8.81h-15a.88.88 0 1 0 0 1.75M17 63.74l-5 .08a10.16 10.16 0 0 1-.3-20.32.88.88 0 0 0 0-1.76A11.91 11.91 0 0 0 0 53.84a11.66 11.66 0 0 0 3.65 8.33 11.74 11.74 0 0 0 8.41 3.4l5-.08a.88.88 0 1 0 0-1.75M29.27 63.87h-3.06a.88.88 0 0 0 0 1.75h3.06a.82.82 0 0 0 .87-.88.87.87 0 0 0-.87-.87M36.06 63.87H33a.88.88 0 1 0 0 1.75h3.06a.83.83 0 0 0 .87-.88.88.88 0 0 0-.87-.87M39.78 65.62h3.06a.88.88 0 0 0 0-1.75h-3.06a.88.88 0 0 0 0 1.75M49.63 63.87h-3.06a.88.88 0 0 0 0 1.75h3.06a.88.88 0 0 0 0-1.75M56.51 63.87h-3a.88.88 0 1 0 0 1.75h3a.83.83 0 0 0 .88-.88.88.88 0 0 0-.88-.87M15.69 43.35h4.78a.88.88 0 0 0 0-1.75h-4.78a.88.88 0 0 0 0 1.75"
          />
        </g>
      </g>
    </svg>
  );
}

PenIcon.propTypes = {
  fill: PropTypes.string,
};

const MemoPenIcon = React.memo(PenIcon);
export default MemoPenIcon;
