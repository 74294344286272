export const getAvatarName = (feedBack) => {
  if (!feedBack.feedbackFromId) {
    return `${feedBack?.User?.firstName?.slice(0, 1).toUpperCase()} ${feedBack?.User?.lastName.slice(0, 1).toUpperCase()}`;
  }
  if (!feedBack.Author) {
    return '';
  }
  return `${feedBack?.Author?.firstName?.slice(0, 1).toUpperCase()} ${feedBack?.Author?.lastName?.slice(0, 1).toUpperCase()}`;
};

export const getAuthorName = (feedBack) => {
  if (!feedBack.feedbackFromId) {
    return `${feedBack?.User?.firstName} ${feedBack?.User?.lastName}`;
  }
  if (!feedBack.Author) {
    return '';
  }
  return `${feedBack?.Author?.firstName} ${feedBack?.Author?.lastName}`;
};
