import React, { useState } from 'react';
import { TextArea } from 'antd-mobile';
import { SendOutlined } from '@ant-design/icons';
import nodeEmoji from 'node-emoji';
import { useDispatch } from 'react-redux';
import emojiRegex from 'emoji-regex';
import PropTypes from 'prop-types';
import API from '../../services/apiService';
import { addFeedback, addFeedbackLoading } from '../../redux/reducers/feedBackReducer';
import { userPropTypes } from './propTypes';

const InputMessage = ({ user }) => {
  const emojiRex = emojiRegex();
  const dispatch = useDispatch();
  const [isFocus, setIsFocus] = useState(false);
  const [messageValue, setMessageValue] = useState('');

  const onSendMessage = async () => {
    if (messageValue && messageValue.trim()) {
      const dataLoading = {
        Author: {
          id: user.id,
        },
        usersSeen: [],
        notes: messageValue.trim(),
        date: new Date(),
        isLoadingMessage: true,
      };
      const replaceEmojiToUnicode = nodeEmoji.unemojify(messageValue.trim()).replaceAll(emojiRex, (match) => `[${match.codePointAt(0).toString(16)}]`);
      dispatch(addFeedbackLoading(dataLoading));
      try {
        const { data } = await API.post('/feedbacks', {
          notes: replaceEmojiToUnicode,
          internal: true,
        });
        if (data.statusCode === 200) {
          setMessageValue('');
          dispatch(addFeedback({ ...data.payload, isLoadingMessage: false, usersSeen: [] }));
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <div
      className="feedback_message_wrapper"
    >
      <div
        className="feedback_message_wrapper_textarea"
      >
        <TextArea
          onFocus={() => setIsFocus(true)}
          onBlur={() => setIsFocus(false)}
          placeholder="Your message..."
          rows={1}
          autoSize={{
            maxRows: 3,
          }}
          value={messageValue}
          onChange={(val) => setMessageValue(val)}
        />
        <SendOutlined
          className={`${isFocus ? 'anticon-focused' : 'anticon-unfocused'}`}
          onClick={onSendMessage}
        />
      </div>
    </div>
  );
};

InputMessage.propTypes = {
  user: PropTypes.shape(userPropTypes),
};

export { InputMessage };
