import React from 'react';
import { List } from 'antd';
import PropTypes from 'prop-types';
import { CheckIn } from './CheckIn';

const { Item } = List;

const CheckIns = ({ checkins }) => (
  <List className="checkin-page-content-list">
    {
              checkins.length > 0 && checkins.map((checkin) => (
                <Item key={checkin.id}>
                  <CheckIn
                    id={checkin.id}
                    key={checkin.id}
                    weight={checkin.weight}
                    date={checkin.date}
                  />
                </Item>
              ))
            }
  </List>
);

CheckIns.propTypes = {
  checkins: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    weight: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
  })).isRequired,
};

export { CheckIns };
