import React, { useState } from 'react';
import { Form } from 'antd-mobile';
import PropTypes from 'prop-types';
import Input from '../../../../components/Fields/InputMob';
import { useFormPropTypes } from '../../propTypes';
import { validateAndPost } from '../postService';
import { THIS_FIELD_IS_REQUIRED } from '../../../../CONSTANTS';

const QuizText = ({
  question, name, form, id, plusProgress, minusProgress,
}) => {
  const isInitValidate = form.getFieldValue(name);
  const [isValidate, setIsValidate] = useState(isInitValidate);
  return (
    <Form.Item
      required
      name={name}
      className={`${isValidate ? 'validated' : 'not_validated'}`}
      label={question}
      validateTrigger="onBlur"
      rules={[
        {
          required: true,
          message: THIS_FIELD_IS_REQUIRED,
        },
        {
          validator: async (_, userAnswer) => {
            if (userAnswer.trim()) {
              const { data } = await validateAndPost({ id, name, form });
              if (data.statusCode === 200) {
                plusProgress(id);
                setIsValidate(true);
                return Promise.resolve();
              }
            }
            minusProgress(id);
            setIsValidate(false);
            return Promise.reject();
          },
          message: THIS_FIELD_IS_REQUIRED,
        },
      ]}
    >
      <Input
        bordered={false}
      />
    </Form.Item>
  );
};

QuizText.propTypes = {
  plusProgress: PropTypes.func.isRequired,
  minusProgress: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  form: PropTypes.shape(useFormPropTypes),
};

export { QuizText };
