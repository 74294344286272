import React from 'react';
import { Form } from 'antd-mobile';
import PropTypes from 'prop-types';

const { Item } = Form;

const CheckinListUploader = ({
  checkins, ViewComponent, imagesData, setId, updateCheckin, isUpload, setUpload, getPhotoIcon,
}) => (
  <>
    {
      checkins && checkins.map((checkin) => (!checkin.disabled || !!imagesData[checkin.key] ? (
        <Item className="form_item_button_wrapper" name={checkin.key} key={checkin.key}>
          <ViewComponent
            checkin={checkin}
            title={checkin.text}
            imagesData={imagesData}
            updateCheckin={updateCheckin}
            name={checkin.key}
            setId={setId}
            isUpload={isUpload}
            setUpload={setUpload}
            PhotoIcon={getPhotoIcon(checkin.key)}
          />
        </Item>
      ) : null))
            }
  </>
);

CheckinListUploader.propTypes = {
  checkins: PropTypes.arrayOf(PropTypes.shape({
    disabled: PropTypes.bool.isRequired,
  })).isRequired,
  ViewComponent: PropTypes.elementType.isRequired,
  imagesData: PropTypes.shape({
    backPhotoId: PropTypes.string,
    frontPhotoId: PropTypes.string,
    profilePhotoId: PropTypes.string,
  }).isRequired,
  setId: PropTypes.func.isRequired,
  updateCheckin: PropTypes.func,
  isUpload: PropTypes.bool,
  setUpload: PropTypes.func,
  getPhotoIcon: PropTypes.func,
};

export { CheckinListUploader };
