import React from 'react';
import {
  Checkbox, Col, Row, Space, Typography,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Button, Dialog } from 'antd-mobile';
import { LogoutOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { logout } from '../../redux/asyncServices/authService';
import { getPhysiqueGoalText } from '../../utils/helpers';
import ImgComponent from '../../components/ImgComponent';

const { Title, Text } = Typography;

const ProfilePageNotEdit = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    physiqueGoal, competing, startDate, LowMacro, StandardMacro, HighMacro,
  } = useSelector((state) => state.profile.user);

  const signOutHandler = async () => {
    await dispatch(logout(history));
    history.push('/login');
  };

  const onLogout = () => Dialog.confirm({
    confirmText: 'OK',
    cancelText: 'Cancel',
    bodyClassName: 'dialog_content',
    content: 'Are you sure you want to log out?',
    // maskStyle: { background: 'rgba(51, 51, 51, 0.5)' },
    maskClassName: 'dialog_mask',
    onConfirm: async () => {
      await signOutHandler();
    },
  });

  return (
    <>
      <Col span={24} className="user_profile_content_item_title">
        <ImgComponent src="/assets/images/profile-text.svg" alt="upload-photo" className="user_profile_content_item_title_img" />
      </Col>
      <Col span={24}>
        <Row gutter={[{ span: 8 }, { xs: 8, sm: 16, md: 24 }]} className="user_profile_content_items">
          <Col span={24} className="user_profile_content_item">
            <Title level={5}>Start Date:</Title>
            <Text>{startDate && moment(startDate).format('YYYY/MM/DD')}</Text>
            <Text>{startDate && moment(startDate).fromNow()}</Text>
          </Col>
          <Col span={24} className="user_profile_content_item">
            <Title level={5}>Competing:</Title>
            <Space className="form_item_checkbox_wrapper">
              <Text>Yes</Text>
              <Checkbox className="form_checkbox" checked={competing} />
              <Text>No</Text>
              <Checkbox className="form_checkbox" checked={!competing} />
            </Space>
          </Col>
          <Col span={24} className="user_profile_content_item">
            <Title level={5}>Physique Goal:</Title>
            <Text>{physiqueGoal && getPhysiqueGoalText(physiqueGoal)}</Text>
          </Col>
          <Col span={24} className="user_profile_content_item">
            <Title level={5}>Mindset Goal:</Title>
            <Text>Learn to Love Myself</Text>
          </Col>

          <Col span={24} className="user_profile_content_item">
            <Title level={5}>Low Macro:</Title>
            <Text>
              {`
              ${Math.floor(!!LowMacro?.protein && LowMacro?.protein)} Protein | 
              ${Math.floor(!!LowMacro?.carb && LowMacro?.carb)} Carb | 
              ${Math.floor(!!LowMacro?.fat && LowMacro?.fat)} Fat
              `}
            </Text>
          </Col>

          <Col span={24} className="user_profile_content_item">
            <Title level={5}>Standard Macro:</Title>
            <Text>
              {`
              ${Math.floor(!!StandardMacro?.protein && StandardMacro?.protein)} Protein | 
              ${Math.floor(!!StandardMacro?.carb && StandardMacro?.carb)} Carb | 
              ${Math.floor(!!StandardMacro?.fat && StandardMacro?.fat)} Fat
              `}
            </Text>
          </Col>

          <Col span={24} className="user_profile_content_item">
            <Title level={5}>High Macro:</Title>
            <Text>
              {`
              ${Math.floor(!!HighMacro?.protein && HighMacro?.protein)} Protein | 
              ${Math.floor(!!HighMacro?.carb && HighMacro?.carb)} Carb | 
              ${Math.floor(!!HighMacro?.fat && HighMacro?.fat)} Fat
              `}
            </Text>
          </Col>
          <Col span={24} className="form_item_button_wrapper">
            <Button
              onClick={onLogout}
              className="form_button user_profile_content_button"
            >
              <Text>Log out</Text>
              <LogoutOutlined />
            </Button>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default ProfilePageNotEdit;
