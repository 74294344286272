import React from 'react';
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';
import { compose } from '../../utils/helpers';
import withoutAuthBackground from '../../hocks/withoutAuthBackground';

function InternalServerError() {
  return (
    <Result
      status="500"
      title="500"
      subTitle="Sorry, something went wrong."
      extra={(
        <Button type="primary">
          <Link to="/checkins">Back Home</Link>
        </Button>
              )}
    />
  );
}

export default compose(withoutAuthBackground)(InternalServerError);
