import React from 'react';
import { StarOutlined, StarFilled } from '@ant-design/icons';
import PropTypes from 'prop-types';

export default function StarSMP({ className = '' }) {
  return (
    <div className={`${className}-wrapper`}>
      <StarOutlined className={`${className}-icon-outlined`} />
      <StarFilled className={`${className}-icon-filled`} />
    </div>
  );
}

StarSMP.propTypes = {
  className: PropTypes.string.isRequired,
};
