import React from 'react';
import {
  DownOutlined,
  HomeOutlined,
} from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { Layout } from 'antd';
import { useHistory } from 'react-router';
import './_bottomNavigation.less';
import 'antd/dist/antd.css';
import { TabBar } from 'antd-mobile';
import {
  CheckCircleOutline,
  QuestionCircleOutline,
  UserCircleOutline,
  FileOutline,
} from 'antd-mobile-icons';
import withAuth from '../../hocks/withAuth';
import useScreenHeight from '../../hooks/useScreenHeight';
import QuizIcon from '../../icon/QuizIcon';
import useUpdateFeedbacks from '../../hooks/useUpdateFeedbacks';

const { Footer } = Layout;

const BottomNavigation = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { unreadCount } = useUpdateFeedbacks();
  const setRouteActive = (value) => value !== 'logout' && history.push(value);

  const tabs = [
    {
      icon: <HomeOutlined />,
      key: '/welcome',
      title: 'Welcome',
    },
    {
      icon: <QuizIcon className="quiz_icon" height="1em" width="1em" />,
      key: '/quiz',
      title: 'Quiz',
    },
    {
      icon: <QuestionCircleOutline />,
      key: '/support',
      title: 'Support',
    },
    {
      icon: <UserCircleOutline />,
      key: '/profile',
      title: 'Profile',
    },
    {
      icon: <CheckCircleOutline />,
      key: '/checkins',
      title: 'Check in',
    },
    {
      icon: <FileOutline />,
      key: '/feedback',
      title: 'Feedback',
      badge: unreadCount > 0 ? unreadCount : null,
    },
  ];

  const withNavigation = (pathName) => `/${pathName.toString().split('/')[1]}`;
  const { scrollBottom } = useScreenHeight();
  return (
    <Footer className="footer footer_navigation footer_navigation_wrapper">
      {scrollBottom && <DownOutlined className="footer_down_button" />}
      <TabBar activeKey={withNavigation(pathname)} onChange={(value) => setRouteActive(value)}>
        {tabs.map((item) => (
          <TabBar.Item key={item.key} icon={item.icon} title={item.title} {...item} />
        ))}
      </TabBar>
    </Footer>
  );
};

export default withAuth(BottomNavigation);
