import { createSlice } from '@reduxjs/toolkit';
import {
  getFeedBacks,
  getMoreFeedBacks,
  getUnreadCountsFeedback,
} from '../asyncServices/feedBackService';
import { FETCH_FEEDBACKS_PAGE_SIZE } from '../../utils/vars';

const initialState = {
  isLoading: true,
  feedBacks: [],
  totalCount: 0,
  unreadCount: 0,
  hasMore: false,
};

const feedBackSlice = createSlice({
  name: 'feedBackReducer',
  initialState,
  reducers: {
    addFeedbackLoading: (state, { payload }) => {
      state.feedBacks = [...state.feedBacks, payload];
    },
    addFeedback: (state, { payload }) => {
      state.feedBacks = [
        payload,
        ...state.feedBacks,
      ].filter(({ isLoadingMessage }) => !isLoadingMessage);
    },

  },
  extraReducers: (builder) => {
    builder.addCase(getFeedBacks.pending, () => {
      // state.isLoading = true;
    });
    builder.addCase(getUnreadCountsFeedback.fulfilled, (state, { payload }) => {
      state.unreadCount = payload.unreadCount;
    });
    builder.addCase(getUnreadCountsFeedback.rejected, (state) => {
      state.unreadCount = 0;
    });
    builder.addCase(getFeedBacks.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.feedBacks = [
        ...payload.feedbacks,
        ...state.feedBacks.splice(FETCH_FEEDBACKS_PAGE_SIZE, state.feedBacks.length),
      ];
      state.unreadCount = 0;
      state.totalCount = payload.totalCount;
    });
    builder.addCase(getFeedBacks.rejected, (state) => {
      state.isLoading = true;
      state.feedbacks = [];
    });
    builder.addCase(getMoreFeedBacks.fulfilled, (state, { payload }) => {
      state.feedBacks = [...state.feedBacks, ...payload.feedbacks];
    });
    builder.addCase(getMoreFeedBacks.rejected, (state) => {
      state.isLoading = true;
    });
  },
});

export const { addFeedback, addFeedbackLoading } = feedBackSlice.actions;

export default feedBackSlice.reducer;
