import PropTypes from 'prop-types';

export const useFormPropTypes = {
  getFieldError: PropTypes.func.isRequired,
  getFieldWarning: PropTypes.func.isRequired,
  getFieldValue: PropTypes.func.isRequired,
  getFieldsError: PropTypes.func.isRequired,
  getInternalHooks: PropTypes.func.isRequired,
  isFieldTouched: PropTypes.func.isRequired,
  isFieldValidating: PropTypes.func.isRequired,
  isFieldsTouched: PropTypes.func.isRequired,
  isFieldsValidating: PropTypes.func.isRequired,
  resetFields: PropTypes.func.isRequired,
  setFields: PropTypes.func.isRequired,
  setFieldsValue: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  validateFields: PropTypes.func.isRequired,
};

export const answerPropTypes = {
  id: PropTypes.string.isRequired,
  quizid: PropTypes.string.isRequired,
  userid: PropTypes.string.isRequired,
  userAnswer: PropTypes.string.isRequired,
  prompt: PropTypes.string.isRequired,
  questionNumber: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  unitAnswer: PropTypes.string,
  question: PropTypes.string.isRequired,
  customAttributes: PropTypes.string,
  choices: PropTypes.string,
};
