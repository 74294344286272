import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

require('dotenv').config();

/**
 * Сomponent that allows to override any meta tags dynamically
 */
const MetaDecorator = ({ location }) => {
  const [currentRoute, setCurrentRoute] = useState();

  useEffect(() => {
    const path = location.pathname.slice(1);
    const titlePrefix = path ? path[0].toUpperCase() + path.slice(1) : 'SMP Mobile';

    setCurrentRoute(titlePrefix);
  }, [location]);

  return (
    <Helmet>
      <title>{`${process.env.REACT_APP_SITE_TITLE} - ${currentRoute}`}</title>
    </Helmet>
  );
};

MetaDecorator.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(MetaDecorator);
