import React from 'react';
import { Typography } from 'antd';
import PropTypes from 'prop-types';

const { Paragraph } = Typography;

const CheckInHelpArea = ({ children, className }) => (
  <div className={`${className}-help-area`}>
    <Paragraph className={`${className}-help-area-text`}>
      {children}
    </Paragraph>
  </div>
);

CheckInHelpArea.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
};

export { CheckInHelpArea };
