import React from 'react';
import PropTypes from 'prop-types';

const CheckinList = ({ checkins, ViewComponent }) => (
  <>
    {
            checkins && checkins.map((checkin) => (
              <ViewComponent key={checkin.key} checkin={checkin} />
            ))
        }
  </>
);

CheckinList.propTypes = {
  checkins: PropTypes.arrayOf(PropTypes.object).isRequired,
  ViewComponent: PropTypes.elementType.isRequired,
};

export { CheckinList };
