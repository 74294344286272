import React, { useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import PropTypes from 'prop-types';
import { Spin } from 'antd';

const LazyScroll = ({
  children, fetch, data, totalCount,
}) => {
  const scrollRef = useRef(null);
  return (
    <div
      id="scrollableDiv"
      className="feedback_lazy"
      style={{
        height: document.body.clientHeight - 110,
      }}
      ref={scrollRef}
    >
      <InfiniteScroll
        className="feedback_lazy_content"
        dataLength={data.length}
        next={fetch}
        inverse
        hasMore={data.length < totalCount}
        loader={(
          <div
            style={{
              textAlign: 'center',
            }}
          >
            <Spin className="feedback_lazy_spinner" />
          </div>
)}
        scrollableTarget="scrollableDiv"
      >
        {children}
      </InfiniteScroll>
    </div>
  );
};

LazyScroll.propTypes = {
  totalCount: PropTypes.number.isRequired,
  children: PropTypes.arrayOf(PropTypes.any),
  fetch: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export { LazyScroll };
