import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Col } from 'antd';
import { Button } from 'antd-mobile';
import { EditProfileForm } from './EditProfileForm';
import { authorizeMyFitnessPal, getUserProfile, updateUser } from '../../redux/asyncServices/profileServices';
import { successColor, notifyDialog, errorColor } from '../../utils/notify';
import { ProfilePageMyFitnessPalWrapper } from './ProfilePageMyFitnessPalWrapper';
import { ProfilePageEditMobileWrapper } from './ProfilePageEditMobileWrapper';
import { setUpdateUser } from '../../redux/reducers/profileReducer';

const ProfilePageEdit = ({ showEditFields }) => {
  const dispatch = useDispatch();
  const [isShowFitnessPal, setIsShowFitnessPal] = useState(false);
  const [isShowMobileForm, setIsShowMobileForm] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const user = useSelector((state) => state.profile.user);
  const onCancel = () => showEditFields();

  const onShowMobileForm = () => {
    setIsShowMobileForm(true);
    setIsShowFitnessPal(false);
  };

  const onCloseMobileForm = () => {
    setIsShowFitnessPal(false);
    setIsShowMobileForm(false);
  };

  const onShowFitnessPal = () => {
    setIsShowFitnessPal(true);
    setIsShowMobileForm(false);
  };

  const onCloseFitnessPal = () => {
    setIsShowFitnessPal(false);
    setIsShowMobileForm(false);
  };

  const onFinishMobileNumber = async ({ phoneNumber }) => {
    setIsLoadingSubmit(true);
    try {
      const response = await updateUser({ phoneNumber: `+${phoneNumber.replace(/\+/g, '')}` });
      if (response.statusCode === 200) {
        setIsLoadingSubmit(false);
        setIsShowMobileForm(false);
        onCancel();
        notifyDialog({
          title: 'Saved',
          titleColor: successColor,
        });
        dispatch(setUpdateUser(response.payload));
      }
    } catch (e) {
      setIsLoadingSubmit(false);
    }
  };

  const onFinishAuthorizeMyFitnessPal = async ({ mfpLogin, mfpPassword }) => {
    try {
      setIsLoadingSubmit(true);
      const response = await authorizeMyFitnessPal({ mfpLogin, mfpPassword });
      if (response.statusCode === 200) {
        setIsLoadingSubmit(false);
        setIsShowFitnessPal(false);
        onCancel();
        return notifyDialog({
          title: 'Saved',
          titleColor: successColor,
        });
      }
    } catch (e) {
      setIsLoadingSubmit(false);
      return notifyDialog({
        title: e[0].mfp,
        titleColor: errorColor,
      });
    }
    return setIsLoadingSubmit(false);
  };
  const onFinishChangePassword = async ({ password, oldPassword, confirmPassword }) => {
    if (password && oldPassword && confirmPassword) {
      const data = await updateUser({
        ...user, password, oldPassword, confirmPassword,
      });
      if (data) {
        notifyDialog({
          title: 'User updated successfully',
          titleColor: successColor,
        });
        dispatch(getUserProfile());
        showEditFields();
      }
    }
  };

  return (
    <>
      {/* // <Row gutter={[0, 25]} justify="center"> */}
      <Col span={24} className="form_item_button_wrapper profile_myfitnesspal_login_button">
        <Button
          onClick={onShowFitnessPal}
          className="form_button"
        >
          MyFitnessPal Login
        </Button>
      </Col>
      <Col span={24} className="form_item_button_wrapper profile_myfitnesspal_login_button">
        <Button
          onClick={onShowMobileForm}
          className="form_button"
        >
          Mobile Number
        </Button>
      </Col>
      <Col span={24}>
        {
          !isShowFitnessPal && !isShowMobileForm && (
          <EditProfileForm
            onFinish={onFinishChangePassword}
            onCancel={onCancel}
            values={{ ...user }}
          />
          )
        }
        {
          isShowFitnessPal && (
          <ProfilePageMyFitnessPalWrapper
            onFinish={onFinishAuthorizeMyFitnessPal}
            isLoadingSubmit={isLoadingSubmit}
            onCancel={onCloseFitnessPal}
          />
          )
        }
        {
          isShowMobileForm && (
          <ProfilePageEditMobileWrapper
            onCancel={onCloseMobileForm}
            onFinish={onFinishMobileNumber}
            phoneNumber={user.phoneNumber}
            isLoadingSubmit={isLoadingSubmit}
          />
          )
        }
      </Col>
      {/* // </Row> */}
    </>
  );
};

ProfilePageEdit.propTypes = {
  showEditFields: PropTypes.func.isRequired,
};

export default ProfilePageEdit;
