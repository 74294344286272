import API from '../../../services/apiService';

export const validateAndPost = async ({
  id, name, form, unitAnswer = null,
}) => {
  const userAnswer = form.getFieldValue(name).trim();
  form.setFieldsValue({ name: userAnswer });
  return API.post(`/questions/${id}/answer`, {
    userAnswer,
    unitAnswer,
  });
};
