import { createSlice } from '@reduxjs/toolkit';
import { getCurrentCheckin, getUserCheckins } from '../asyncServices/checkinsService';

const initialState = {
  checkins: {
    isLoading: true,
    checkins: [],
  },
  checkin: {
    isLoading: true,
    checkin: {},
    units: {
      mass: 'kgs',
      length: 'cms',
    },
  },
  parsedCsvData: [],
};

const checkinsSlice = createSlice({
  name: 'checkinReducer',
  initialState,
  reducers: {
    setParsedCsvData: (state, { payload }) => {
      state.parsedCsvData = payload;
    },
    checkinReset: (state) => ({ ...state, checkin: { ...initialState.checkin } }),
  },
  extraReducers: (builder) => {
    builder.addCase(getUserCheckins.pending, (state) => {
      state.checkins.isLoading = true;
    });
    builder.addCase(getUserCheckins.fulfilled, (state, { payload }) => {
      state.checkins.isLoading = false;
      state.checkins.checkins = [...payload.checkins];
    });
    builder.addCase(getCurrentCheckin.pending, (state) => {
      state.checkin.isLoading = true;
    });
    builder.addCase(getCurrentCheckin.fulfilled, (state, { payload }) => {
      state.checkin.isLoading = false;
      state.checkin.checkin = { ...payload };
    });
  },
});

export const { checkinReset, setParsedCsvData } = checkinsSlice.actions;
export default checkinsSlice.reducer;
