import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import Form from 'antd/lib/form';
import { useHistory } from 'react-router-dom';
import { LockOutlined } from '@ant-design/icons';
import { Layout, Typography } from 'antd';
import { errorColor, notifyDialog, successColor } from '../../utils/notify';
import { validationAntService as validation } from '../../utils/validationAntService';
import { compose, getUrlParam, validateRequired } from '../../utils/helpers';
import './_resetPasswordConfirm.less';
import 'antd/dist/antd.css';
import LoadingComponent from '../../components/LoadingComponent';
import withoutAuthBackground from '../../hocks/withoutAuthBackground';

const { validatePassword } = validation();

const { Content } = Layout;

const ResetPasswordConfirmPage = () => {
  const history = useHistory();
  const [linkValid, setLinkValid] = useState(false);
  const [isRedirectSpinnerVisible, setRedirectSpinnerVisible] = useState(false);
  const [form] = Form.useForm();

  const validateMatchPasswords = async () => {
    const password = form.getFieldValue('password');
    const passwordRepeat = form.getFieldValue('passwordRepeat');

    if (password !== passwordRepeat) {
      return Promise.reject('Passwords do not match');
    }
    return Promise.resolve();
  };

  const onFinish = async ({ password, passwordRepeat }) => {
    try {
      await axios.post('/api/auth/reset-password/confirm', {
        token: getUrlParam('token'),
        password,
        confirmPassword: passwordRepeat,
      });
      notifyDialog({
        title: 'Password has been reset successfully!',
        titleColor: successColor,
      });
      setRedirectSpinnerVisible(true);
      setTimeout(() => history.push('/login'), 2000);
    } catch (err) {
      const errors = err.response.data.errors[0].messages.map((error) => `${error}`);
      notifyDialog({
        title: errors,
        titleColor: errorColor,
      });
    }
  };

  const checkResetToken = async (token) => {
    try {
      const result = await axios.post('/api/auth/reset-password/validate', { token });
      return result.data.payload.valid;
    } catch {
      return false;
    }
  };

  useEffect(async () => {
    const token = getUrlParam('token');
    const status = await checkResetToken(token);
    if (status) {
      return setLinkValid(true);
    }
    return null;
  }, []);

  if (!linkValid) {
    return (
      <Layout className="login-wrapper page__layout">
        <Content className="main">
          <div className="login__form">
            <div className="login__img flex justify-center">
              <img src="/assets/images/smp-logo.png" alt="logo" />
            </div>
          </div>
          <div className="container-sm">
            <Form className="login__form">
              <Typography.Title level={2}>This link is invalid</Typography.Title>
              <Form.Item className="form_item_button_wrapper">
                <Button
                  size="large"
                  className="form_button"
                  block
                  onClick={() => history.push('/login')}
                  type="primary"
                >
                  Back to login
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Content>
      </Layout>
    );
  }

  if (isRedirectSpinnerVisible) {
    return (
      <LoadingComponent />
    );
  }

  return (
    <Layout className="login-wrapper page__layout">
      <Content className="main">
        <div className="login__form">
          <div className="login__img flex justify-center">
            <img src="/assets/images/smp-logo.png" alt="logo" />
          </div>
          <div className="container-sm">
            <Typography.Title level={2}>Reset password</Typography.Title>
            <Form
              form={form}
              className="login-form"
              name="reset-password"
              onFinish={onFinish}
              initialValues={{ password: '', passwordRepeat: '' }}
            >
              <Form.Item
                className="form_item_input_wrapper"
                name="password"
                validateTrigger={['onBlur']}
                rules={[() => ({
                  async validator(_, value) {
                    await validateRequired(value);
                    await validatePassword(value);
                    return Promise.resolve(value);
                  },
                }),
                { required: true, message: '' },
                { max: 20, message: 'Password must be shorter than or equal to 20 characters' },
                ]}
              >
                <Input.Password
                  className="form_input"
                  bordered={false}
                  autoComplete="off"
                  size="large"
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder="Password"
                />
              </Form.Item>
              <Form.Item
                className="form_item_input_wrapper"
                name="passwordRepeat"
                validateTrigger={['onBlur']}
                rules={[() => ({
                  async validator(_, value) {
                    await validateRequired(value);
                    // await validatePassword(value);
                    await validateMatchPasswords();
                    return Promise.resolve(value);
                  },
                }), { required: true, message: '' }]}
              >
                <Input.Password
                  className="form_input"
                  bordered={false}
                  autoComplete="off"
                  size="large"
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder="Repeat password"
                />
              </Form.Item>
              <Form.Item className="form_item_button_wrapper">
                <Button className="form_button" size="large" type="primary" htmlType="submit" block>
                  Reset
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Content>
    </Layout>
  );
};

export default compose(withoutAuthBackground)(ResetPasswordConfirmPage);
