import React, { useRef, useState } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { CHECK_IN_HELP_TEXT } from '../../utils/vars';
import useOutsideClickHandler from '../../hooks/useOutsideClickHandler';

const CheckInFormItemLabel = ({ className = 'input-label', text, tooltipText = CHECK_IN_HELP_TEXT }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const handleShowTooltip = () => setShowTooltip(true);
  const handleClickOutside = () => setShowTooltip(false);
  const wrapperRef = useRef(null);

  useOutsideClickHandler(wrapperRef, handleClickOutside);

  return (
    <div className={`${className}-wrapper`}>
      {showTooltip && <div ref={wrapperRef} className={`${className}-tooltip`}>{tooltipText}</div>}
      <div className={`${className}-item`}>
        <InfoCircleOutlined onClick={handleShowTooltip} className={`${className}-icon`} />
      </div>
      <div className={`${className}-item`}>
        <span className={`${className}-text`}>{text}</span>
      </div>
    </div>
  );
};

CheckInFormItemLabel.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
};

export default CheckInFormItemLabel;
