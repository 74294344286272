export const Routes = {
  LOGIN: '/login',
  WELCOME: '/welcome',
  QUIZ: '/quiz',
  FEEDBACK: '/feedback',
  SUPPORT: '/support',
  PROFILE: '/profile',
  CHECK_INS: '/checkins',
  CHECK_IN_ID: '/checkins/:id',
  CHECK_IN_ADD: '/checkins/add',
  FORBIDDEN: '/forbidden-page',
  SERVERERROR: '/server-error-page',
  RESET_PASSWORD: '/reset-password',
  RESET_PASSWORD_CONFIRM: '/reset-password/confirm',
};
