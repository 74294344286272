import { createSlice } from '@reduxjs/toolkit';
import { getQuizQuestions } from '../asyncServices/quizServices';

const initialState = {
  isLoading: true,
  questionsWithAnswers: [],
  questions: [],
};

const quizSlice = createSlice({
  name: 'quizReducer',
  initialState,
  reducers: {
    isLoadingAction: (state) => {
      state.isLoading = true;
    },
    plusAnswer: (state, { payload }) => {
      const findAnswer = state.questionsWithAnswers.find((question) => question.id === payload);
      if (!findAnswer) {
        const answerObject = state.questions.find((question) => question.id === payload);
        state.questionsWithAnswers.push(answerObject);
      }
    },
    minusAnswer: (state, { payload }) => {
      state.questionsWithAnswers = state.questionsWithAnswers
        .filter((question) => question.id !== payload);
    },
  },
  extraReducers: ((builder) => {
    builder.addCase(getQuizQuestions.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getQuizQuestions.fulfilled, (state, { payload }) => {
      const questionsWithAnswer = payload.filter((question) => question.answer);
      const progressPercent = (questionsWithAnswer.length / payload.length) * 100;
      state.isLoading = false;
      state.questions = payload;
      state.questionsWithAnswers = questionsWithAnswer;
      state.progressPercent = progressPercent;
    });
  }),
});

export const {
  isLoadingAction,
  plusAnswer,
  minusAnswer,
} = quizSlice.actions;

export default quizSlice.reducer;
