import React, { useState, useEffect } from 'react';
import { Dialog } from 'antd-mobile';
import * as serviceWorker from './serviceWorkerRegistration';

const ServiceWorkerWrapper = () => {
  const [showReload, setShowReload] = useState(!!JSON.parse(localStorage.getItem('waiting')));
  const [waitingWorker, setWaitingWorker] = useState(null);

  const onSWUpdate = (registration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
    localStorage.setItem('waiting', JSON.stringify(true));
  };

  useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate });
    if (showReload && !waitingWorker) {
      navigator.serviceWorker.getRegistration()
        .then((registration) => setWaitingWorker(registration ? registration.waiting : null));
    }
  }, []);

  const reloadPage = () => {
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    setShowReload(false);
    localStorage.removeItem('waiting');
    window.location.reload(true);
  };

  return (

    <Dialog
      visible={showReload}
      maskClassName="dialog_mask"
      content="A new version is available!"
      actions={[
        {
          key: 'confirm',
          text: 'Reload',
          onClick: () => reloadPage(),
        },
      ]}
    />

  );
};

export default ServiceWorkerWrapper;
