import Dexie from 'dexie';

/**
 * Dexie database config for work with indexedDb
 */

const db = new Dexie('smpDB');
db.version(1).stores({
  loggedUser: 'id, name, email, company, active, isAdmin, *permissions',
});

export default db;
