import axios from 'axios';
import moment from 'moment';
import { Badge } from 'antd-mobile';
import { goalOptions } from './vars';

export function Logger(moduleName) {
  let lastTimestamp;

  const getTimestampOffset = () => {
    if (!lastTimestamp) {
      lastTimestamp = Date.now();
      return '+0ms';
    }
    const newTimestamp = Date.now();
    const offset = newTimestamp - lastTimestamp;
    lastTimestamp = newTimestamp;

    return `+${offset}ms`;
  };

  return {
    debug: (message, ...args) => {
      console.debug(
        `%c ${moduleName} ${getTimestampOffset()}: %c ${message}`,
        'background: lightgray',
        'color: green',
        ...args,
      );
    },

    info: (message, ...args) => {
      console.info(
        `%c ${moduleName} ${getTimestampOffset()}: %c ${message}`,
        'background: lightgray',
        'color: blue',
        ...args,
      );
    },
    warn: (message, ...args) => {
      console.warn(
        `%c ${moduleName} ${getTimestampOffset()}: %c ${message}`,
        'background: lightgray',
        'color: orange',
        ...args,
      );
    },
    error: (message, ...args) => {
      console.error(
        `%c ${moduleName} ${getTimestampOffset()}: %c ${message}`,
        'background: lightgray',
        'color: red',
        ...args,
      );
    },
  };
}

export function Watcher(onChange, value) {
  return {
    current: value,
    getValue() {
      return this.current;
    },
    setValue(newValue) {
      if (this.current !== newValue) {
        this.current = newValue;
        onChange(this.current);
      }
    },
  };
}

const logger = Logger('helpers.js');
const onlineWatcher = Watcher((value) => {
  logger.info(`App goes ${value}`);
});

async function getMeRemote() {
  // return await axios
  return axios
    .get('/api/auth/current')
    .then(async (resp) => {
      // In case successful request we get object with user
      logger.debug(
        'getMeRemote. After fetch then: /api/auth/current; resp.data.payload:',
        resp.data.payload,
      );
      return resp.data.payload;
    })
    .catch(async (error) => {
      /* If your error object contains a response field, it means that
                       the server responded with a 4xx / 5xx error. */
      if (error.response) {
        // in data object we have statusCode and message
        logger.debug(
          'getMeRemote. After fetch catch(online): /api/auth/current; error.response.data:',
          error.response.data,
        );
        return error.response.data;
      }
      // if error object dont have response field it means exist some problem with request
      logger.debug(
        'getMeRemote. After fetch catch(offline): /api/auth/current; error.message:',
        error.message,
      );
      return error;
    });
}

export const getUrlParam = (param) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
};

export const changeSortDirectionTitle = (title) => {
  if (title === 'ascend') {
    return 'ASC';
  }
  if (title === 'descend') {
    return 'DESC';
  }
  if (title === 'ASC') {
    return 'ascend';
  }
  if (title === 'DESC') {
    return 'descend';
  }
  return '';
};

export async function isOnline() {
  if (!navigator.onLine) {
    onlineWatcher.setValue('OFFLINE');
    return false;
  }

  const meResp = await getMeRemote();

  const online = meResp.message !== 'Network Error';

  const onlineValue = online ? 'ONLINE' : 'OFFLINE';
  onlineWatcher.setValue(onlineValue);
  return online;
}

export async function me() {
  const meResp = await getMeRemote();
  if (meResp.message) {
    return { user: null, statusCode: meResp.statusCode };
  }

  return { user: meResp, statusCode: null };
}

export const compose = (...funcs) => (comp) => funcs.reduceRight((wrapped, f) => f(wrapped), comp);

export function randomDomId() {
  const s4 = () => Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
  return `${s4() + s4()}`;
}

export function isStandalone() {
  return window.matchMedia('(display-mode: standalone)').matches;
}

export const convertToDateFormat = (value) => {
  const dateParts = value.split('-');
  const year = dateParts[0];
  const month = dateParts[1];
  const day = dateParts[2];
  return `${month}/${day}/${year}`;
};

export const getTodayDateHtmlFormat = () => {
  const dateNow = new Date();
  let humanMonth = dateNow.getMonth() + 1;
  if (humanMonth < 10) humanMonth = `0${humanMonth}`;
  const year = dateNow.getFullYear();
  let day = dateNow.getDate();
  if (day < 10) day = `0${day}`;
  return `${year}-${humanMonth}-${day}`;
};

export const convertToHtmlFormat = (humanDateFormat) => {
  const dateParts = humanDateFormat.split('/');
  const month = dateParts[0];
  const day = dateParts[1];
  const year = dateParts[2];
  return `${year}-${month}-${day}`;
};

export function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function deepCopy(data) {
  return JSON.parse(JSON.stringify(data));
}

export const shallowEqual = (object1, object2) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }

  return true;
};

export const sortByParam = (direction, data, field) => data.sort((prev, next) => {
  if (direction === 'ASC') {
    return prev[field].localeCompare(next[field]);
  }
  return next[field].localeCompare(prev[field]);
});

export const isOnlySpaces = (string) => {
  const regexp = /^\s*$/;
  return regexp.test(String(string).toLowerCase());
};

export const isPlatform = (OS = []) => (OS.includes(navigator.platform.toUpperCase()));

export const getPhysiqueGoalText = (physiqueGoal) => {
  const goalObj = goalOptions.find((goal) => goal.value === physiqueGoal);
  if (goalObj) {
    return goalObj.label;
  }

  return physiqueGoal || null;
};

export const ucFirst = (str = '') => {
  str = str.toLowerCase();
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const validateRequired = async (value, message) => {
  if (!value || isOnlySpaces(value)) {
    return Promise.reject(new Error(message || 'This field is required'));
  }
  return Promise.resolve();
};

export const validateNoSpaces = async (value) => {
  const regexp = /^\S+$/;
  if (!regexp.test(value)) {
    return Promise.reject(new Error('This field is required'));
  }
  return Promise.resolve();
};

export const getLastOnlineDate = (date) => {
  if (!date) {
    return '';
  }
  const isCurrentMinutes = moment(date).isSame(new Date(), 'minute');
  const isCurrentHour = moment(date).isSame(new Date(), 'hour');
  const isCurrentDay = moment(date).isSame(new Date(), 'day');
  const isCurrentYear = moment(date).isSame(new Date(), 'year');
  if (isCurrentMinutes) {
    return 'Online';
  }
  if (isCurrentHour) {
    return moment(new Date(date), 'YYYYMMDD').fromNow();
  }
  if (isCurrentDay) {
    return moment(date).format('HH:mm');
  }
  if (isCurrentYear) {
    return moment(date).format('MM/DD HH:mm');
  }
  if (!isCurrentDay || !isCurrentYear) {
    return 'Unknown';
  }
  return moment(date).format('YYYY/MM/DD HH:mm');
};

export const getDateMessage = (date) => {
  if (!date) {
    return '';
  }
  const isCurrentHour = moment(date).isSame(new Date(), 'hour');
  const isCurrentDay = moment(date).isSame(new Date(), 'day');
  const isCurrentYear = moment(date).isSame(new Date(), 'year');
  if (isCurrentHour) {
    return moment(new Date(date), 'YYYYMMDD').fromNow();
  }
  if (isCurrentDay) {
    return moment(date).format('HH:mm');
  }
  if (isCurrentYear) {
    return moment(date).format('MM/DD HH:mm');
  }
  if (!isCurrentDay || !isCurrentYear) {
    return 'Unknown';
  }
  return moment(date).format('YYYY/MM/DD HH:mm');
};

export const getOnlineStatus = (date) => {
  if (!date) {
    return null;
  }
  const isCurrentMinutes = moment(date).isSame(new Date(), 'minute');
  if (isCurrentMinutes) {
    return Badge.dot;
  }
  return null;
};
