import React, { useEffect, useState } from 'react';
import {
  message, Col, Row,
} from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import API from '../../services/apiService';
import { errorColor, notifyDialog } from '../../utils/notify';
import UploadButton from '../../components/UploadButton';
import ImageWithSkeleton from '../../components/ImageWithSkeleton';
import TextWithLeftChildren from '../../components/TextWithLeftChildren';
import TrashIcon from '../../icon/TrashIcon';

const CheckinImgUploader = ({
  title,
  name,
  imagesData,
  setId,
  checkin,
  updateCheckin = null,
  isUpload = null,
  setUpload = null,
  PhotoIcon,
}) => {
  const id = imagesData[name];
  const userId = useSelector((state) => state.auth.user.id);
  const [imgData, setImgData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);

  const disabledButtons = (flag) => {
    if (setUpload) {
      setUpload(flag);
    }
  };

  const MAX_FILE_SIZE = 15000000;

  const handleDeleteImage = async () => {
    // eslint-disable-next-line no-use-before-define
    await removePhoto();
  };

  const handleReloadImage = () => {
    setReload(false);
  };

  const notifyActions = [
    {
      key: 'reload',
      text: 'Reload',
      onClick: async () => {
        await handleReloadImage();
      },
    },
    {
      key: 'delete',
      text: 'Delete',
      onClick: async () => {
        await handleDeleteImage();
      },
    },
  ];

  const deletePhoto = async (photoId) => {
    if (!photoId.includes(userId)) {
      photoId = `${userId}/${photoId}`;
    }

    try {
      const response = await API.delete(`aws/${photoId}`);
      return response;
    } catch (e) {
      notifyDialog({
        title: `Failed to delete ${title}`,
        titleColor: errorColor,
        actions: notifyActions.filter((action) => action.key === 'delete'),
        content: 'Do you want to try deleting the image again?',
      });
    }
    return null;
  };

  const removePhoto = async () => {
    setLoading(true);
    disabledButtons(true);
    const response = await deletePhoto(id);

    if (!!response && response.statusText === 'OK') {
      if (updateCheckin) {
        await updateCheckin({ [name]: null });
      }
      setId({ [name]: null });
      setImgData(null);
    }
    setLoading(false);
    disabledButtons(false);
  };

  const getPhoto = async () => {
    try {
      const response = await API.get(`aws/${id}`);
      if (response.statusText === 'OK') {
        return response.data.payload;
      }
    } catch (e) {
      setReload(true);
      notifyDialog({
        title: `${title} not found.`,
        titleColor: errorColor,
        actions: notifyActions,
        content: 'Do you want to reload or delete the image?',
      });
    }
    return null;
  };

  const setPhoto = async (formData) => {
    try {
      const response = await API.post('aws', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if (response.statusText === 'OK') {
        return response.data.payload;
      }
    } catch (e) {
      notifyDialog({
        content: 'Please try again',
        title: `Failed to upload ${title}`,
        titleColor: errorColor,
      });
    }
    return null;
  };

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const uploadImage = async (options) => {
    const { onSuccess, onError, file } = options;

    if (!file.type.startsWith('image/')) {
      return message.error(`${file.name} is not a image file`);
    }

    if (file.size > MAX_FILE_SIZE) {
      return message.error(`The ${file.name} file is too large. Please enter a file with a valid file size no larger than ${MAX_FILE_SIZE / 1e+6} MB`);
    }

    try {
      disabledButtons(true);
      const formData = new FormData();
      formData.append('file', file);
      setLoading(true);
      const data = await setPhoto(formData);

      if (data) {
        setId({ [name]: data });
        getBase64(file, (image) => setImgData(image));
        onSuccess('Ok');
      }
      setLoading(false);
    } catch (err) {
      onError({ err });
    }
    disabledButtons(false);
    return null;
  };

  useEffect(() => {
    let cleanupFunction = false;

    const fetchData = async () => {
      if (!!id && !imgData) {
        setLoading(true);
        const data = await getPhoto();
        if (!cleanupFunction) {
          if (data) {
            setImgData(data);
            setReload(true);
          }
        }
      }
      setLoading(false);
    };
    if (!cleanupFunction && !reload) {
      fetchData();
    }
    return () => {
      cleanupFunction = true;
      setId(null);
    };
  }, [reload]);

  const TextWithImg = (text) => (
    <TextWithLeftChildren
      className="text-with-image"
      text={text}
    >
      <div className="text-with-image-icon">
        <PhotoIcon
          width={20}
          height={20}
        />
      </div>
    </TextWithLeftChildren>
  );

  return (
    <Row gutter={[8, 24]}>
      {!!id
        && (
        <Col span={24} className="check-in-page-content-form-photo">
          <div className="check-in-page-content-form-photo-header">
            {TextWithImg(title)}
            {!checkin.disabled && (
              <button
                type="button"
                className="check-in-page-content-form-photo-header-button"
                onClick={removePhoto}
                disabled={isUpload}
              >
                <TrashIcon width={20} height={20} />
              </button>
            )}
          </div>
          <ImageWithSkeleton
            className="check-in-page-content-form-photo"
            loading={loading}
            alt={title}
            data={imgData}
            preview={!!imgData}
            size={250}
          />
        </Col>
        )}
      {!checkin.disabled
          && !imgData && (
          <Col span={24}>
            <UploadButton
              uploadProps={{
                accept: '.jpg, .jpeg, .png',
                className: 'flex justify-center',
                showUploadList: false,
                customRequest: uploadImage,
              }}
              buttonProps={{
                className: 'form_button',
                loading,
                disabled: isUpload,
              }}
            >
              {TextWithImg(title)}
            </UploadButton>
          </Col>
      )}
    </Row>
  );
};

CheckinImgUploader.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  imagesData: PropTypes.shape({
    frontPhotoId: PropTypes.string,
    backPhotoId: PropTypes.string,
    profilePhotoId: PropTypes.string,
  }).isRequired,
  setId: PropTypes.func.isRequired,
  checkin: PropTypes.shape({
    disabled: PropTypes.bool.isRequired,
  }).isRequired,
  updateCheckin: PropTypes.func,
  isUpload: PropTypes.bool,
  setUpload: PropTypes.func,
  PhotoIcon: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.elementType,
    PropTypes.oneOf([null]),
  ]).isRequired,
};
export { CheckinImgUploader };
