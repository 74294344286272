import React from 'react';
import moment from 'moment';
import { RightCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

const CheckIn = ({ id, weight, date }) => {
  const history = useHistory();
  const formatDate = moment(date).format('YYYY/MM/DD');

  const handleOpenInfo = () => {
    history.push(`checkins/${id}`);
  };

  return (
    <div
      className="checkin-list-item flex justify-between align-center"
      onClick={handleOpenInfo}
    >
      {`${formatDate} - ${weight}kg `}
      <RightCircleOutlined />
    </div>
  );
};

CheckIn.propTypes = {
  id: PropTypes.string.isRequired,
  weight: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};

export { CheckIn };
