import React from 'react';
import { Form } from 'antd';
import { Button } from 'antd-mobile';
import PropTypes from 'prop-types';
import Input from '../../components/Fields/InputWeb';
import InputPassword from '../../components/Fields/InputPassword';

const ProfilePageMyFitnessPalForm = ({
  onCancel, onFinish, mfpLogin, isLoading,
}) => (
  <Form
    onFinish={onFinish}
    initialValues={{
      mfpLogin: mfpLogin || '',
      mfpPassword: '',
    }}
    className="edit_profile_form"
  >
    <Form.Item
      rules={[
        {
          required: true,
          message: 'This field is required',
        },
      ]}
      className="form_item_input_wrapper"
      name="mfpLogin"
    >
      <Input
        autoComplete="off"
        className="form_input"
        size="large"
        placeholder="MyFitnessPal login"
      />
    </Form.Item>
    <Form.Item
      rules={[
        {
          required: true,
          message: 'This field is required',
        },
      ]}
      className="form_item_input_wrapper"
      name="mfpPassword"
    >
      <InputPassword
        autoComplete="new-password"
        className="form_input"
        size="large"
        placeholder="MyFitnessPal password"
      />
    </Form.Item>
    <Form.Item
      className="form_item_button_wrapper"
    >
      <Button
        className="form_button"
        onClick={onCancel}
      >
        Cancel
      </Button>
    </Form.Item>
    <Form.Item
      className="form_item_button_wrapper"
    >
      <Button
        loading={isLoading}
        type="primary"
        className="form_button"
        htmlType="submit"
      >
        Save
      </Button>
    </Form.Item>
  </Form>
);

ProfilePageMyFitnessPalForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  mfpLogin: PropTypes.string.isRequired,
};

export { ProfilePageMyFitnessPalForm };
