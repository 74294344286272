import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Spin } from 'antd';
import { errorColor, notifyDialog } from '../utils/notify';

const withSync = (Component) => {
  const NewComponent = (props) => {
    const isOnline = useSelector((state) => state.common.isOnline);
    const isModalOpen = useSelector((state) => state.common.isModalOpen);
    const [syncLoading] = useState(false);

    useEffect(() => {
      if (!navigator.onLine) {
        notifyDialog({
          content: 'Please try again later',
          titleColor: errorColor,
          title: 'Failed to load page.',
        });
      }
    }, [isOnline, isModalOpen, navigator.onLine]);

    if (syncLoading) {
      return (
        <div className="sync_spinner">
          <h3>Sync is being processed. Please wait...</h3>
          <Spin size="large" />
        </div>
      );
    }

    return <Component {...props} />;
  };

  return NewComponent;
};

export default withSync;
