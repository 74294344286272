import React from 'react';
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';
import { compose } from '../../utils/helpers';
import withoutAuthBackground from '../../hocks/withoutAuthBackground';

function NotFoundPage() {
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={(
        <Button type="primary">
          <Link to="/checkins">Back Home</Link>
        </Button>
      )}
    />
  );
}

export default compose(withoutAuthBackground)(NotFoundPage);
