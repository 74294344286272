import React from 'react';
import PropTypes from 'prop-types';

const ImgComponent = ({ src, alt, className = '' }) => (
  <div className={className}>
    <img src={src} alt={alt} />
  </div>
);

ImgComponent.propTypes = {
  className: PropTypes.string,
  alt: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
};

export default ImgComponent;
