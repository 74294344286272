import { createSlice } from '@reduxjs/toolkit';

const crudSlice = createSlice({
  name: 'commonReducer',
  initialState: {
    activePage: 'Checkins',
    isOnline: false,
    isModalOpen: false,
  },
  reducers: {
    setCurrentPage(state, action) {
      state.activePage = action.payload;
    },
    updateOnlineStatus(state, action) {
      state.isOnline = action.payload;
    },
    updateModalOpenStatus(state, action) {
      state.isModalOpen = action.payload;
    },
  },
});

export const { setCurrentPage, updateOnlineStatus, updateModalOpenStatus } = crudSlice.actions;

export default crudSlice.reducer;
