import React from 'react';
import PropTypes from 'prop-types';

const TextWithLeftChildren = ({ className, text, children }) => (
  <div className={className}>
    {children}
    <div className={`${className}-text`}>
      <span>{text}</span>
    </div>
  </div>
);

TextWithLeftChildren.propTypes = {
  className: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
    PropTypes.elementType,
  ]).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.elementType,
  ]),
};

export default TextWithLeftChildren;
