import React from 'react';

const withHideFooter = (Component) => (props) => {
  // eslint-disable-next-line react/prop-types
  const { onBlur, onFocus } = props;
  const footer = document.querySelector('.footer_navigation_wrapper');

  const onHideFooter = () => {
    footer.style.display = 'none';
    if (typeof onFocus === 'function') {
      onFocus();
    }
  };

  const onVisibleFooter = () => {
    footer.style.display = 'block';
    if (typeof onBlur === 'function') {
      onBlur();
    }
  };

  return (
    <Component {...props} onFocus={onHideFooter} onBlur={onVisibleFooter} />
  );
};

export default withHideFooter;
