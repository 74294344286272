import React from 'react';
import { ErrorBlock, Mask } from 'antd-mobile';
import useOrientationChange from '../../hooks/useOrientationChange';

export default function OrientationNotAvailable() {
  const { isLandscape } = useOrientationChange();
  return (
    <Mask
      visible={isLandscape}
      opacity={1}
    >
      <ErrorBlock
        status="default"
        fullPage
        title="Landscape orientation is not available on the device."
        description="Change the orientation of the device to portrait."
      />
    </Mask>
  );
}
