import { QuizInputUnits } from '../Form/QuizNumberUnits/QuizInputUnits';
import { QuizNumber } from '../Form/QuizNumber/QuizNumber';
import { QuizUnitFeetIn } from '../Form/QuizNumberUnits/QuizUnitFeetIn';
import { QuizUnit } from '../Form/QuizNumberUnits/QuizUnit';
import { getUnitsValue } from '../Form/QuizNumberUnits/unitsService';

export const numberService = (question) => {
  if (question.customAttributes) {
    const unitsView = JSON.parse(question.customAttributes).unitChoices.map((unit) => {
      if (unit === 'feet_in') {
        return {
          unit,
          ViewComponent: QuizUnitFeetIn,
        };
      }
      return {
        unit,
        ViewComponent: QuizUnit,
      };
    });
    return {
      ...question,
      customAttributes: JSON.parse(question.customAttributes).unitChoices,
      unitsView,
      name: question.prompt.split(' ').join(''),
      value: getUnitsValue(question),
      ViewComponent: QuizInputUnits,
    };
  }
  return {
    ...question,
    name: question.prompt.split(' ').join(''),
    value: question.answer?.userAnswer || '',
    ViewComponent: QuizNumber,
  };
};
