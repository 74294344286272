import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormPropTypes } from '../../propTypes';
import { THIS_FIELD_IS_REQUIRED } from '../../../../CONSTANTS';

const QuizInputUnits = ({
  id, name, form, question, unitsView, plusProgress, minusProgress,
}) => {
  const isInitValidate = form.getFieldValue(name);
  const [isValidateQuestion, setIsValidateQuestion] = useState(!!isInitValidate);
  const [isValidateUnits, setIsValidateUnits] = useState(isInitValidate);
  const [isRequiredError, setIsRequiredError] = useState(false);
  return (
    <div
      className="quiz_attributes_block"
    >
      <div
        className="adm-list-item-title"
      >
        <label
          aria-required
          htmlFor={name}
          className={`${isValidateQuestion ? 'validated' : 'not_validated'} adm-list-item-title`}
        >
          {question}
          <span className="adm-form-item-label-required">*</span>
        </label>
      </div>
      <div
        className="quiz_attributes"
      >
        <div
          className="quiz_input_attributes"
        >
          {
                unitsView.length > 0
                && unitsView.map(({ unit, ViewComponent }) => (
                  <ViewComponent
                    isValidateUnits={isValidateUnits}
                    setIsValidateUnits={setIsValidateUnits}
                    setIsRequiredError={setIsRequiredError}
                    setIsValidateQuestion={setIsValidateQuestion}
                    plusProgress={plusProgress}
                    minusProgress={minusProgress}
                    id={id}
                    key={unit}
                    unit={unit}
                    name={name}
                    form={form}
                  />
                ))
          }
        </div>
        {
          isRequiredError && (<div className="adm-form-item-footer">{THIS_FIELD_IS_REQUIRED}</div>)
        }
      </div>
    </div>
  );
};

QuizInputUnits.propTypes = {
  plusProgress: PropTypes.func.isRequired,
  minusProgress: PropTypes.func.isRequired,
  unitsView: PropTypes.arrayOf(PropTypes.object).isRequired,
  form: PropTypes.shape(useFormPropTypes).isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
};

export { QuizInputUnits };
