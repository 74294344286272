import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFrown,
  faFrownOpen,
  faLaughWink,
  faMeh,
  faSadTear,
  faSmile,
  faSmileBeam, faStar,
} from '@fortawesome/free-solid-svg-icons';
import API from '../../services/apiService';
import { errorColor, notifyDialog } from '../../utils/notify';
import { THIS_FIELD_IS_INCORRECT, THIS_FIELD_IS_REQUIRED } from '../../CONSTANTS';

const validateUpperZero = (_, value) => {
  if (value > 0 && value < 9999) {
    return Promise.resolve();
  }
  return Promise.reject();
};

export const updateCheckin = async ({ checkinId, values }) => {
  try {
    const response = await API.put(`/checkins/${checkinId}`, { ...values });
    if (response.statusText === 'OK') {
      return response.data;
    }
  } catch (e) {
    if (e?.messages && e.messages.length > 0) {
      notifyDialog({
        title: e.messages[0],
        titleColor: errorColor,
      });
    }
  }
  return null;
};

export const createCheckin = async (values) => {
  try {
    const response = await API.post('/checkins', { ...values });
    if (response.statusText === 'OK') {
      return response.data;
    }
  } catch (e) {
    if (e?.messages && e.messages.length > 0) {
      notifyDialog({
        title: e.messages[0],
        titleColor: errorColor,
      });
    }
  }
  return null;
};

export const INPUTS_FIELDS = [
  {
    key: 'weight',
    text: 'Weight',
    unit: 'kgs',
    disabled: false,
    type: 'decimal',
    placeholder: 0,
    rules: [
      {
        required: true,
        message: THIS_FIELD_IS_REQUIRED,
      },
      {
        validator: validateUpperZero,
        message: THIS_FIELD_IS_INCORRECT,
      },
    ],
  },
  {
    key: 'waist',
    text: 'Waist',
    unit: 'cms',
    disabled: false,
    placeholder: 0,
    type: 'decimal',
    rules: [
      {
        required: true,
        message: THIS_FIELD_IS_REQUIRED,
      },
      {
        validator: validateUpperZero,
        message: THIS_FIELD_IS_INCORRECT,
      }],
  },
  {
    key: 'hips',
    text: 'Hips',
    placeholder: 0,
    unit: 'cms',
    disabled: false,
    type: 'decimal',
    rules: [
      {
        required: true,
        message: THIS_FIELD_IS_REQUIRED,
      },
      {
        validator: validateUpperZero,
        message: THIS_FIELD_IS_INCORRECT,
      },
    ],
  },
  {
    key: 'lowerBelly',
    text: 'Lower belly',
    unit: 'cms',
    disabled: false,
    placeholder: 0,
    type: 'decimal',
    rules: [{
      required: true,
      message: THIS_FIELD_IS_REQUIRED,
    },
    {
      validator: validateUpperZero,
      message: THIS_FIELD_IS_INCORRECT,
    }],
  },
  {
    key: 'upperQuad',
    text: 'Upper quad',
    placeholder: 0,
    unit: 'cms',
    disabled: false,
    type: 'decimal',
    rules: [
      {
        required: true,
        message: THIS_FIELD_IS_REQUIRED,
      },
      {
        validator: validateUpperZero,
        message: THIS_FIELD_IS_INCORRECT,
      },
    ],
  },
  {
    key: 'midQuad',
    text: 'Middle quad',
    unit: 'cms',
    disabled: false,
    placeholder: 0,
    type: 'decimal',
    rules: [{
      required: true,
      message: THIS_FIELD_IS_REQUIRED,
    },
    {
      validator: validateUpperZero,
      message: THIS_FIELD_IS_INCORRECT,
    }],
  },
];

export const RADIOS_FIELDS_EMOJI = [
  {
    key: 'stressScore',
    value: 0,
    text: 'Stress score',
    rules: [
      {
        required: true,
        message: THIS_FIELD_IS_REQUIRED,
      },
      {
        validator: (_, value) => {
          if (value > 0) {
            return Promise.resolve();
          }
          return Promise.reject();
        },
        message: THIS_FIELD_IS_REQUIRED,
      }],
    icons: [
      {
        value: 1,
        icon: (checked) => (checked ? (
          <FontAwesomeIcon
            icon={faLaughWink}
            size="sm"
            color="#36e621"
          />
        )
          : (
            <FontAwesomeIcon
              icon={faLaughWink}
              size="sm"
              color="grey"
            />
          )),
      },
      {
        value: 2,
        icon: (checked) => (checked ? (
          <FontAwesomeIcon
            icon={faSmileBeam}
            size="sm"
            color="#82e12a"
          />
        )
          : (
            <FontAwesomeIcon
              icon={faSmileBeam}
              size="sm"
              color="grey"
            />
          )),
      },
      {
        value: 3,
        icon: (checked) => (checked ? (
          <FontAwesomeIcon
            icon={faSmile}
            size="sm"
            color="#bae318"
          />
        )
          : (
            <FontAwesomeIcon
              icon={faSmile}
              size="sm"
              color="grey"
            />
          )),
      },
      {
        value: 4,
        icon: (checked) => (checked ? (
          <FontAwesomeIcon
            icon={faMeh}
            size="sm"
            color="#e6df20"
          />
        )
          : (
            <FontAwesomeIcon
              icon={faMeh}
              size="sm"
              color="grey"
            />
          )),
      },
      {
        value: 5,
        icon: (checked) => (checked ? (
          <FontAwesomeIcon
            icon={faFrown}
            size="sm"
            color="#e38c29"
          />
        )
          : (
            <FontAwesomeIcon
              icon={faFrown}
              size="sm"
              color="grey"
            />
          )),
      },
      {
        value: 6,
        icon: (checked) => (checked ? (
          <FontAwesomeIcon
            icon={faFrownOpen}
            size="sm"
            color="#d56d41"
          />
        )
          : (
            <FontAwesomeIcon
              icon={faFrownOpen}
              size="sm"
              color="grey"
            />
          )),
      },
      {
        value: 7,
        icon: (checked) => (checked ? (
          <FontAwesomeIcon
            icon={faSadTear}
            size="sm"
            color="#d84243"
          />
        )
          : (
            <FontAwesomeIcon
              icon={faSadTear}
              size="sm"
              color="grey"
            />
          )),
      },
    ],
  },
];

export const RATES_FIELDS_STARS = [
  {
    key: 'trainingScore',
    text: 'Training score',
    value: 0,
    disabled: false,
    icon: <FontAwesomeIcon
      icon={faStar}
      size="sm"
    />,
    rules: [{
      required: true,
      message: THIS_FIELD_IS_REQUIRED,
    },
    {
      validator: (_, value) => {
        if (value > 0) {
          return Promise.resolve();
        }
        return Promise.reject();
      },
      message: THIS_FIELD_IS_REQUIRED,
    }],
  },
  {
    key: 'foodScore',
    text: 'Food score',
    value: 0,
    disabled: false,
    icon: <FontAwesomeIcon
      icon={faStar}
      size="sm"
    />,
    rules: [
      {
        required: true,
        message: THIS_FIELD_IS_REQUIRED,
      },
      {
        validator: (_, value) => {
          if (value > 0) {
            return Promise.resolve();
          }
          return Promise.reject();
        },
        message: THIS_FIELD_IS_REQUIRED,
      },

    ],
  },
  {
    key: 'waterScore',
    text: 'Water score',
    value: 0,
    disabled: false,
    icon: <FontAwesomeIcon
      icon={faStar}
      size="sm"
    />,
    rules: [{
      required: true,
      message: THIS_FIELD_IS_REQUIRED,
    },
    {
      validator: (_, value) => {
        if (value > 0) {
          return Promise.resolve();
        }
        return Promise.reject();
      },
      message: THIS_FIELD_IS_REQUIRED,
    }],
  },
  {
    key: 'sleepScore',
    text: 'Sleep score',
    value: 0,
    disabled: false,
    icon: <FontAwesomeIcon
      icon={faStar}
      size="sm"
    />,
    rules: [{
      required: true,
      message: THIS_FIELD_IS_REQUIRED,
    },
    {
      validator: (_, value) => {
        if (value > 0) {
          return Promise.resolve();
        }
        return Promise.reject();
      },
      message: THIS_FIELD_IS_REQUIRED,
    }],
  },
];

export const SWITCH_FIELDS = [
  {
    key: 'sick',
    text: 'Sick',
    value: false,
    disabled: false,
  },
  {
    key: 'menstruating',
    text: 'Menstruating',
    value: false,
    disabled: false,
  },
];

export const FILE_FIELDS = [
  {
    key: 'frontPhotoId',
    text: 'Front Photo',
    value: null,
    disabled: false,
    rules: [],
  },
  {
    key: 'backPhotoId',
    text: 'Back Photo',
    value: null,
    disabled: false,
    rules: [],
  },
  {
    key: 'profilePhotoId',
    text: 'Side Photo',
    value: null,
    disabled: false,
    rules: [],
  },
];

export const CALORIES_FIELDS = [
  {
    key: 'actualCalories',
    text: 'Actual Calories',
    value: null,
    disabled: false,
    rules: [],
  },
  {
    key: 'nutritionExport',
    text: 'Nutrition Export',
    value: null,
    disabled: false,
    rules: [],
  },
];
