import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader } from 'antd';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { CloseCircleOutline } from 'antd-mobile-icons';
import { LeftCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd-mobile';
import { getCurrentCheckin } from '../../redux/asyncServices/checkinsService';
import { getCheckin } from '../../redux/selectors/checkinsSelectors';
import {
  INPUTS_FIELDS,
  RATES_FIELDS_STARS,
  SWITCH_FIELDS,
  FILE_FIELDS,
  RADIOS_FIELDS_EMOJI, updateCheckin,
} from './checkinService';
import LoadingComponent from '../../components/LoadingComponent';
import CheckinForm from './CheckinForm';
import { compose } from '../../utils/helpers';
import withOnline from '../../hocks/withOnline';
import withSync from '../../hocks/withSync';
import { errorColor, notifyDialog, successColor } from '../../utils/notify';
import { checkinReset } from '../../redux/reducers/checkinsReducer';
import './_checkinEdit.less';
import ImgComponent from '../../components/ImgComponent';
import TextWithLeftChildren from '../../components/TextWithLeftChildren';
import PenIcon from '../../icon/PenIcon';
import withAuthBackground from '../../hocks/withAuthBackground';
import { authorizeMyFitnessPal, getAuthMyFitnessPal } from '../../redux/asyncServices/profileServices';
import { setMfpAccount } from '../../redux/reducers/profileReducer';

const EditCheckin = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [isDisabledFields, setIsDisabledFields] = useState(true);
  const [isShowMfp, setIsShowMfp] = useState(false);
  const [isLoadingMfp, setIsLoadingMfp] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  // const mfpAccount = useSelector((state) => state.profile.mfpAccount);
  const checkinId = useSelector((state) => state.checkins.checkin.checkin.id);
  const isLoading = useSelector((state) => state.checkins.checkin.isLoading);
  const checkin = useSelector(getCheckin);
  const {
    date,
    notes,
    actualCalories,
    nutritionExport,
    averageCalories,
    averageProtein,
    averageCarbohydrates,
    averageFat,
  } = checkin;

  const onSubmitMfp = async ({ mfpLogin, mfpPassword }) => {
    try {
      setIsLoadingMfp(true);
      const response = await authorizeMyFitnessPal({ mfpLogin, mfpPassword });
      if (response.statusCode === 200) {
        dispatch(setMfpAccount(response.payload));
        setIsLoadingMfp(false);
        setIsShowMfp(false);
        return notifyDialog({
          title: 'Saved',
          titleColor: successColor,
        });
      }
    } catch (e) {
      setIsLoadingMfp(false);
      setIsShowMfp(false);
      return notifyDialog({
        title: e[0].mfp,
        titleColor: errorColor,
      });
    }
    setIsShowMfp(false);
    return setIsLoadingMfp(false);
  };

  const formatDate = moment(new Date(date)).format('DD/MM/YY');
  const dateFromNow = moment(date).fromNow();

  const handleDisabled = (value) => setIsDisabledFields(value);

  const getCheckinById = (currentId) => dispatch(getCurrentCheckin({ id: currentId }));

  const resetCheckinState = () => dispatch(checkinReset());

  const checkinInputs = [...INPUTS_FIELDS].map((item) => ({
    ...item,
    value: +checkin[item.key],
    disabled: isDisabledFields,
  }));

  const checkinRateEmoji = [...RADIOS_FIELDS_EMOJI].map((item) => ({
    ...item,
    value: checkin[item.key],
    disabled: isDisabledFields,
  }));

  const checkinRate = [...RATES_FIELDS_STARS].map((item) => ({
    ...item,
    value: checkin[item.key],
    disabled: isDisabledFields,
  }));

  const checkinSwitch = [...SWITCH_FIELDS].map((item) => ({
    ...item,
    value: checkin[item.key],
    disabled: isDisabledFields,
  }));

  const checkinFile = [...FILE_FIELDS].map((item) => ({
    ...item,
    value: checkin[item.key],
    disabled: isDisabledFields,

  }));

  const initialCsv = {
    actualCalories: actualCalories || null,
    averageCalories: averageCalories || null,
    averageProtein: averageProtein || null,
    averageFat: averageFat || null,
    averageCarbohydrates: averageCarbohydrates || null,
    nutritionExport: nutritionExport?.data
      ? Buffer.from(nutritionExport.data).toString() : null,
  };

  const initialValues = [
    ...checkinInputs,
    ...checkinRate,
    ...checkinRateEmoji,
    ...checkinSwitch,
    ...checkinFile,
  ].reduce((prev, { key, value }) => ({
    ...prev,
    [key]: value,
  }), {});

  const extra = ({ disabled = null }) => (
    <div className="check-in-edit-header-extra">
      { !isDisabledFields

        ? (
          <CloseCircleOutline onClick={() => {
            if (!disabled) {
              getCheckinById(id);
              handleDisabled(!isDisabledFields);
            }
          }}
          />
        )
        : (
          <PenIcon
            width={25}
            height={25}
            onClick={() => handleDisabled(!isDisabledFields)}
          />
        )}
    </div>
  );

  const FooterButton = (disabled = null) => !isDisabledFields && (
  <div className="form_item_button_wrapper">
    <Button
      type="submit"
      loading={isLoadingSubmit}
      className="form_button"
      disabled={disabled}
    >
      <TextWithLeftChildren
        className="text-with-image"
        text="Submit Check In"
      >
        <ImgComponent
          src="/assets/images/send-check-in.svg"
          alt="send-check-in-button"
          className="text-with-image-icon"
        />
      </TextWithLeftChildren>
    </Button>
  </div>
  );

  const onBack = ({ disabled }) => {
    if (!disabled) {
      history.goBack();
    }
  };

  const Header = (disabled) => (
    <PageHeader
      backIcon={<LeftCircleOutlined className="check-in-edit-header-back-icon" />}
      className="page-header check-in-edit-header page_header_style_wrapper"
      onBack={() => onBack(disabled)}
      title={(
        <div className="check-in-edit-header-title">
          {(`${dateFromNow}
          Check in: 
          ${formatDate}
          `).toUpperCase()}
        </div>
)}
      extra={extra(disabled)}
    />
  );

  const updatePhoto = async (values) => updateCheckin({ checkinId, values });

  const onSubmit = async (values) => {
    setIsLoadingSubmit(true);

    // if (!mfpAccount.mfpLogin) {
    //   setIsLoadingSubmit(false);
    //   return notifyDialog({
    //     titleColor: errorColor,
    //     title: 'MFP credentials have not been set.
    //     Do you want to add them or proceed without nutrition data?',
    //     actions: [
    //       {
    //         key: 'close',
    //         text: 'Add MFP credentials',
    //         className: 'form_button',
    //         onClick: () => setIsShowMfp(true),
    //       },
    //       {
    //         key: 'accept',
    //         text: 'Submit checkin',
    //         onClick: async () => {
    //           const data = await updateCheckin({ checkinId, values });
    //           if (data) {
    //             setIsLoadingSubmit(false);
    //             history.push('/checkins');
    //             notifyDialog({
    //               titleColor: successColor,
    //               title: 'Success update',
    //             });
    //           }
    //         },
    //       },
    //     ],
    //   });
    // }

    const data = await updateCheckin({ checkinId, values });
    if (data) {
      setIsLoadingSubmit(false);
      history.push('/checkins');
      return notifyDialog({
        titleColor: successColor,
        title: 'Success update',
      });
    }
    return null;
  };

  useEffect(() => {
    dispatch(getAuthMyFitnessPal());
    if (id) {
      getCheckinById(id);
    }
    return () => resetCheckinState();
  }, [id]);

  if (isLoading) {
    return (
      <LoadingComponent />
    );
  }

  return (
    <CheckinForm
      initialValues={initialValues}
      Header={Header}
      Footer={FooterButton}
      onSubmit={onSubmit}
      updateCheckin={updatePhoto}
      notes={notes}
      mfp={{
        isShowMfp,
        setIsShowMfp,
        isLoadingMfp,
        onSubmitMfp,
      }}
      isDisabledFields={isDisabledFields}
      data={{
        checkinInputs,
        checkinRate,
        checkinRateEmoji,
        checkinFile,
        checkinSwitch,
        initialCsv,
      }}

    />
  );
};

export default compose(withAuthBackground, withOnline, withSync)(EditCheckin);
