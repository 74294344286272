import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../services/apiService';
import { errorColor, notifyDialog } from '../../utils/notify';

export const getUserProfile = createAsyncThunk('USER_PROFILE',
  async () => {
    const { data } = await API.get('/users/me');
    return data.payload;
  });

export const getAuthMyFitnessPal = createAsyncThunk('MFP',
  async () => {
    const { data } = await API.get('/users/mfp');
    return data.payload;
  });

export const authorizeMyFitnessPal = async (values) => {
  try {
    const { data } = await API.put('/users/mfp', values);
    return data;
  } catch (e) {
    return notifyDialog({
      title: e[0].mfp,
      titleColor: errorColor,
    });
  }
};

export const updateUser = async (data) => {
  try {
    const response = await API.put('/users', { ...data });
    if (response.statusText === 'OK') {
      return response.data;
    }
  } catch (e) {
    if (e?.messages && e.messages.length > 0) {
      notifyDialog({
        title: e.messages[0],
        titleColor: errorColor,
      });
    }
  }
  return null;
};
