import React, { useState } from 'react';
import { Form } from 'antd-mobile';
import PropTypes from 'prop-types';
import { useFormPropTypes } from '../../propTypes';
import { validateAndPost } from '../postService';
import Input from '../../../../components/Fields/InputMob';
import { THIS_FIELD_IS_INCORRECT, THIS_FIELD_IS_REQUIRED } from '../../../../CONSTANTS';

const QuizNumber = ({
  id, question, name, form, plusProgress, minusProgress,
}) => {
  const isInitValidate = form.getFieldValue(name);
  const [isValidate, setIsValidate] = useState(!!isInitValidate);

  return (
    <div
      className={`quiz_input_number ${isValidate ? 'validated' : 'not_validated'}`}
    >
      <Form.Item
        required
        label={question}
        validateTrigger="onBlur"
        rules={[
          {
            validator: (_, userAnswer) => {
              if (!userAnswer) {
                return Promise.reject();
              }
              return Promise.resolve();
            },
            message: THIS_FIELD_IS_REQUIRED,
          },
          {
            validator: async (_, userAnswer) => {
              if (userAnswer > 0 && userAnswer < 9999) {
                const { data } = await validateAndPost({ id, form, name });
                if (data.statusCode === 200) {
                  setIsValidate(true);
                  plusProgress(id);
                  return Promise.resolve();
                }
              }
              setIsValidate(false);
              minusProgress(id);
              return Promise.reject();
            },
            message: THIS_FIELD_IS_INCORRECT,
          },
        ]}
        key={id}
        name={name}
      >
        <Input
          type="number"
          autoComplete="off"
          decimalSeparator="decimal"
          bordered={false}
        />
      </Form.Item>
    </div>
  );
};

QuizNumber.propTypes = {
  plusProgress: PropTypes.func.isRequired,
  minusProgress: PropTypes.func.isRequired,
  form: PropTypes.shape(useFormPropTypes).isRequired,
  id: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export { QuizNumber };
