import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import { pdfjs } from 'react-pdf';
import App from './App';
import { store, persistor } from './redux/store';
import MetaDecorator from './components/MetaDecorator/MetaDecorator';
import ServiceWorkerWrapper from './ServiceWorkerWrapper';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

ReactDOM.render(
  <>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ConfigProvider locale={enUS}>
          <BrowserRouter basename={process.env.REACT_APP_PUBLIC_URL}>
            <MetaDecorator />
            <App />
          </BrowserRouter>
        </ConfigProvider>
      </PersistGate>
    </Provider>
    <ServiceWorkerWrapper />
  </>,
  document.getElementById('root'),
);
