import * as React from 'react';

function FrontPhotoIcon(props) {
  return (
    <svg viewBox="0 0 50.29 47.92" width="1em" height="1em" {...props}>
      <defs>
        <style>{'.prefix__cls-1{fill:currentColor}'}</style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <g id="prefix__Layer_1-2" data-name="Layer 1">
          <path
            className="prefix__cls-1"
            d="M34 1a1.71 1.71 0 00-.09 1.33 1.77 1.77 0 00.89 1 21.62 21.62 0 11-19.3 0 1.75 1.75 0 001.07-2.23 1.72 1.72 0 00-.89-1 1.78 1.78 0 00-1.37 0 1.7 1.7 0 00-.31.16A25.13 25.13 0 002.63 33.94a25.13 25.13 0 0033.68 11.35 25.09 25.09 0 0011.35-33.68A25.24 25.24 0 0036.31.26 1.75 1.75 0 0034 1z"
          />
          <path
            className="prefix__cls-1"
            d="M31.48 11.13a.8.8 0 00.15-.18 1.64 1.64 0 00.46-1.11v-.06a1.69 1.69 0 00-.47-1.16L26.4 3.39a1.4 1.4 0 00-.59-.39 1.77 1.77 0 00-1.3 0 1.34 1.34 0 00-.59.4l-5.2 5.19a1.8 1.8 0 000 2.46 1.74 1.74 0 002.46 0l2.22-2.2v16.52a1.75 1.75 0 103.49 0V8.81L29.1 11a1.66 1.66 0 002.38.13z"
          />
        </g>
      </g>
    </svg>
  );
}

const MemoFrontPhotoIcon = React.memo(FrontPhotoIcon);
export default MemoFrontPhotoIcon;
