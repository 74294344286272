import React, { useEffect, useState } from 'react';
import {
  Layout, Typography, PageHeader, Row, Col,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { LeftCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { compose } from '../../utils/helpers';
import withOnline from '../../hocks/withOnline';
import withSync from '../../hocks/withSync';
import { getUserProfile } from '../../redux/asyncServices/profileServices';
import LoadingComponent from '../../components/LoadingComponent';
import ProfilePageUserImg from './ProfilePageUserImg';
import ProfilePageNotEdit from './ProfilePageNotEdit';
import ProfilePageEdit from './ProfilePageEdit';
import './_profilePage.less';
import PenIcon from '../../icon/PenIcon';
import withAuthBackground from '../../hocks/withAuthBackground';
import usePersistedState from '../../hooks/usePersistedState';
import API from '../../services/apiService';
import { setUpdateUser } from '../../redux/reducers/profileReducer';
import { errorColor, notifyDialog, successColor } from '../../utils/notify';
import { ProfilePageImgButtons } from './ProfilePageImgButtons';
import withLoadingSpinner from '../../hocks/withLoadingSpinner';

const { Content } = Layout;
const { Title } = Typography;

const MAX_FILE_SIZE = 15000000;

const ProfilePage = ({ setIsLoadingSpinner }) => {
  const reader = new FileReader();
  const dispatch = useDispatch();
  const {
    lastName, firstName, profilePhoto,
  } = useSelector((state) => state.profile.user);
  const isLoading = useSelector((state) => state.profile.isLoading);
  const [isShowEdit, setIsShowEdit] = useState(false);
  const [image, setImage] = usePersistedState('profilePhoto', null);
  const [imageId, setImageId] = usePersistedState('profilePhotoId', null);
  const [newImageFile, setNewImageFile] = useState(null);
  const [newImage, setNewImage] = useState(null);
  const showEditFields = () => {
    setIsShowEdit(!isShowEdit);
    setNewImage(null);
    setNewImageFile(null);
  };

  function getBase64(img, callback) {
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const onRemovePhoto = async () => {
    setIsLoadingSpinner(true);
    const aws = await API.delete(`/aws/${profilePhoto}`);
    if (aws.data.statusCode === 200) {
      const api = await API.put('/users', { profilePhoto: aws.data.payload });
      if (api.data.statusCode === 200) {
        setImage(null);
        setNewImage(null);
        setImageId(null);
        setNewImageFile(null);
        setIsLoadingSpinner(false);
        dispatch(setUpdateUser(api.data.payload));
      }
    }
  };

  const onUploadPhoto = async () => {
    const formData = new FormData();
    formData.append('file', newImageFile);
    try {
      setIsLoadingSpinner(true);
      const aws = await API.post('aws', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if (aws.data.statusCode === 200) {
        const api = await API.put('/users', { profilePhoto: aws.data.payload });
        if (api.data.statusCode === 200) {
          setImage(newImage);
          setImageId(aws.data.payload);
          setNewImage(null);
          setIsLoadingSpinner(false);
          dispatch(setUpdateUser(api.data.payload));
          return notifyDialog({
            titleColor: successColor,
            title: 'Photo saved',
          });
        }
      }
      return notifyDialog({
        titleColor: errorColor,
        title: 'Something went wrong',
      });
    } catch (e) {
      setIsLoadingSpinner(false);
      return notifyDialog({
        titleColor: errorColor,
        title: 'Something went wrong',
      });
    }
  };

  const showRemoveModal = () => {
    if (newImage) {
      setNewImage(null);
      return setNewImageFile(null);
    }
    return notifyDialog({
      titleColor: errorColor,
      title: 'Are you sure you want to remove photo?',
      actions: [{ key: 'close', text: 'Close', className: 'form_button' }, {
        key: 'accept', text: 'Accept', onClick: onRemovePhoto, className: 'form_button',
      }],
    });
  };

  const handleNewImage = (options) => {
    if (options.file.size > MAX_FILE_SIZE) {
      return notifyDialog({
        title: `File cannot be more than ${MAX_FILE_SIZE / 1e+6} MB`,
        titleColor: errorColor,
        content: 'Please upload another photo',
      });
    }
    setNewImageFile(options.file);
    return getBase64(options.file, (newImg) => setNewImage(newImg));
  };

  const extra = () => (isShowEdit ? (
    <CloseCircleOutlined
      onClick={showEditFields}
      className="user_profile_icon"
    />
  ) : (
    <PenIcon
      width={25}
      height={25}
      onClick={showEditFields}
    />
  ));

  useEffect(() => {
    dispatch(getUserProfile());

    return () => {
      setNewImage(null);
      setNewImageFile(null);
      reader.removeEventListener('load', () => {});
    };
  }, []);

  if (isLoading) {
    return (
      <LoadingComponent />
    );
  }

  return (
    <Layout className="user_profile_wrapper page__layout">
      <PageHeader
        backIcon={<LeftCircleOutlined className="user_profile_icon" />}
        className="page-header page_transparent_header"
        title={<span className="user_profile_header_title">Back</span>}
        onBack={() => window.history.back()}
        extra={extra()}
      />
      <Content className="user_profile_content_wrapper page__content__wrapper page_content_with_bg">
        <Row gutter={[{ span: 8 }, { xs: 4, sm: 8, md: 24 }]} justify="center">
          <ProfilePageUserImg
            isShowEdit={isShowEdit}
            lastName={lastName}
            firstName={firstName}
            image={image}
            newImage={newImage}
            setImage={setImage}
            src={profilePhoto}
            imageId={imageId}
            setImageId={setImageId}
            showRemoveModal={showRemoveModal}
          />
          {
            isShowEdit && (
            <ProfilePageImgButtons
              newImage={newImage}
              handleNewImage={handleNewImage}
              onUploadPhoto={onUploadPhoto}
            />
            )
          }
          <Col span={24} className="user_profile_content_full_name">
            <Title level={2}>
              {firstName && lastName && `${firstName} ${lastName}`}
            </Title>
          </Col>
          {!isShowEdit
            ? <ProfilePageNotEdit />
            : <ProfilePageEdit showEditFields={showEditFields} />}
        </Row>
      </Content>
    </Layout>
  );
};

ProfilePage.propTypes = {
  setIsLoadingSpinner: PropTypes.func,
};

export default compose(
  withLoadingSpinner,
  withAuthBackground,
  withOnline,
  withSync,
)(ProfilePage);
