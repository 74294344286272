import React from 'react';

const withoutAuthBackground = (Component) => (props) => (
  <>
    <div className="page_layout_without_auth_background" />
    <Component {...props} />
  </>
);

export default withoutAuthBackground;
