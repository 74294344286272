import React from 'react';
import { Col, Upload } from 'antd';
import { Button } from 'antd-mobile';
import PropTypes from 'prop-types';

const ProfilePageImgButtons = ({
  newImage, handleNewImage, onUploadPhoto,
}) => (
  <>
    {
      !newImage ? (
        <Col span={24} className="flex justify-center">
          <Upload
            accept=".jpg, .jpeg, .png"
            showUploadList={false}
            customRequest={handleNewImage}
            name="avatar"
            listType="picture"
          >
            <Button className="form_button">Upload photo</Button>
          </Upload>
        </Col>
      ) : (
        <Col span={24} className="user_profile_photo_btn flex justify-center">
          <Button className="form_button" onClick={onUploadPhoto}>Save photo</Button>
        </Col>
      )
}
  </>
);

ProfilePageImgButtons.propTypes = {
  newImage: PropTypes.string,
  handleNewImage: PropTypes.func.isRequired,
  onUploadPhoto: PropTypes.func.isRequired,
};

export { ProfilePageImgButtons };
