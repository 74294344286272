import { createSelector } from '@reduxjs/toolkit';
// import moment from 'moment';
import emojiRegex from 'emoji-regex';
import nodeEmoji from 'node-emoji';
import { getDateMessage } from '../../utils/helpers';

const rexBrackets = /\[.*?\]/gu;
const emojiReg = emojiRegex();

const feedBacksSelector = (state) => state.feedBacks.feedBacks;
const unreadCountFeedbacksSelector = (state) => state.feedBacks.unreadCount;

export const getUnreadCountFeedbacksSelector = createSelector(
  [unreadCountFeedbacksSelector],
  (count) => count,
);
// const date = moment(new Date(feedBack.date), 'YYYYMMDD').fromNow();
export const getFeedBacksSelector = createSelector([feedBacksSelector], (feedbacks) => {
  const sortedFeedBacks = [...feedbacks]
    .sort((a, b) => (new Date(a.date) < new Date(b.date) ? 1 : -1));
  const formatFeedBacks = sortedFeedBacks.map((feedBack) => ({
    ...feedBack,
    notes: nodeEmoji.emojify(feedBack.notes).replaceAll(rexBrackets, (match) => {
      try {
        const unicode = match.slice(1, -1);
        const smile = String.fromCodePoint(parseInt(unicode, 16));
        if (smile.match(emojiReg)) {
          return smile;
        }
        return match;
      } catch (e) {
        return match;
      }
    }),
    date: getDateMessage(feedBack.date),
  }));
  return {
    feedBacks: formatFeedBacks,
  };
});
