import * as React from 'react';
import PropTypes from 'prop-types';

function TrashIcon(props) {
  return (
    <svg viewBox="0 0 51.68 51.68" {...props}>
      <defs>
        <style>
          {
            '.prefix__cls-2{fill:currentColor;stroke:#333;stroke-miterlimit:10;stroke-width:.71px}'
          }
        </style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <g id="prefix__Layer_1-2" data-name="Layer 1">
          <path
            d="M25.84 0a25.84 25.84 0 1025.84 25.84A25.87 25.87 0 0025.84 0zm22.32 25.84A22.32 22.32 0 1125.84 3.52a22.34 22.34 0 0122.32 22.32z"
            fill="currentColor"
          />
          <path
            className="prefix__cls-2"
            d="M36.15 12.37h-7v-.77A2.61 2.61 0 0026.54 9h-1.4a2.61 2.61 0 00-2.61 2.61v.77h-7a3.17 3.17 0 00-3.16 3.16v.3a3.18 3.18 0 002.33 3l1.44 19.81a4.35 4.35 0 004.32 4h10.77a4.35 4.35 0 004.31-4L37 18.87a3.17 3.17 0 002.32-3v-.3a3.17 3.17 0 00-3.17-3.2zm-11.51-.77a.5.5 0 01.5-.51h1.4a.51.51 0 01.51.51v.77h-2.41zm8.8 26.93a2.23 2.23 0 01-2.21 2.06H20.46a2.24 2.24 0 01-2.22-2.06L16.82 19h18zM15.53 16.89a1.05 1.05 0 01-1-1.06v-.3a1 1 0 011-1h20.62a1 1 0 011.05 1v.3a1.05 1.05 0 01-1.05 1.06M20.29 37.65l-.77-13.39a1.06 1.06 0 012.11 0l.77 13.39a1.06 1.06 0 01-2.11 0zm4.5.48V24.26a1.05 1.05 0 012.1 0v13.87a1 1 0 11-2.1 0z"
          />
          <path
            className="prefix__cls-2"
            d="M30.33 38.7a1 1 0 01-1-1.05l.77-13.39a1 1 0 112.1 0l-.77 13.39a1 1 0 01-1.1 1.05z"
          />
        </g>
      </g>
    </svg>
  );
}

TrashIcon.propTypes = {
  fill: PropTypes.string,
};

const MemoTrashIcon = React.memo(TrashIcon);
export default MemoTrashIcon;
