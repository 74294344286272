import React from 'react';
import { Typography } from 'antd';
import PropTypes from 'prop-types';
import { ProfilePageEditMobile } from './ProfilePageEditMobile';

const ProfilePageEditMobileWrapper = ({
  onCancel, onFinish, phoneNumber, isLoadingSubmit,
}) => (
  <>
    <Typography.Title level={4} className="flex justify-center fitness-title">
      Mobile Number:
    </Typography.Title>
    <ProfilePageEditMobile
      onCancel={onCancel}
      onFinish={onFinish}
      isLoadingSubmit={isLoadingSubmit}
      phoneNumber={phoneNumber}
    />
  </>
);

ProfilePageEditMobileWrapper.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  isLoadingSubmit: PropTypes.bool.isRequired,
};

export { ProfilePageEditMobileWrapper };
