import React from 'react';

const withAuthBackground = (Component) => (props) => (
  <>
    <div className="page_layout_with_auth_background" />
    <Component {...props} />
  </>
);

export default withAuthBackground;
