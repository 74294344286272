import { createSlice } from '@reduxjs/toolkit';
import { checkAuth } from '../asyncServices/authService';

const crudSlice = createSlice({
  name: 'authReducer',
  initialState: {
    user: null,
    prevLocation: '',
    isLoading: true,
    isAuth: false,
  },
  extraReducers: {
    [checkAuth.pending]: (state) => {
      state.isLoading = true;
    },
    [checkAuth.fulfilled]: (state, action) => {
      state.user = action.payload.user;
      state.prevLocation = action.payload.prevLocation;
      state.isLoading = false;
      state.isAuth = action.payload.isAuth;
    },
  },
});

export default crudSlice.reducer;
