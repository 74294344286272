import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { isOnline, Logger } from '../utils/helpers';
import { updateOnlineStatus } from '../redux/reducers/commonReducer';

const logger = Logger('authService.js');

const withOnline = (Component) => {
  const NewComponent = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
      const checkAuth = async () => {
        const status = await isOnline();
        logger.debug('useOnline. online status:', status);
        dispatch(updateOnlineStatus(status));
      };

      checkAuth();
      const checkAuthIntervalRequest = setInterval(checkAuth, 3000);

      return () => clearInterval(checkAuthIntervalRequest);
    }, []);

    return <Component {...props} />;
  };

  return NewComponent;
};

export default withOnline;
