import { createSelector } from '@reduxjs/toolkit';
import { choiceService } from '../../pages/QuizPage/quizService/choiceService';
import { textareaService } from '../../pages/QuizPage/quizService/textareaService';
import { textService } from '../../pages/QuizPage/quizService/textService';
import { numberService } from '../../pages/QuizPage/quizService/numberService';

const questionsSelector = (state) => state.quiz.questions;
const questionsWithAnswer = (state) => state.quiz.questionsWithAnswers;

export const getProgress = createSelector([questionsSelector, questionsWithAnswer],
  (questions, answers) => {
    const questionsLength = questions.length;
    const answersLength = answers.length;
    return (answersLength / questionsLength) * 100;
  });

export const getQuestions = createSelector([questionsSelector], (questions) => {
  const questionsView = questions.map((question) => {
    const { answerType } = question;
    if (answerType === 'NUMBER') {
      return numberService(question);
    }
    if (answerType === 'TEXTAREA') {
      return textareaService(question);
    }
    if (answerType === 'TEXT') {
      return textService(question);
    }
    if (answerType === 'CHOICE') {
      return choiceService(question);
    }
    return question;
  });

  const initialValues = questionsView.reduce((prev, { name, value }) => ({
    ...prev,
    [name]: value,
  }), {});

  return {
    questionsView,
    initialValues,
  };
});
