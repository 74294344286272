import React from 'react';
import { Form, Rate } from 'antd-mobile';
import PropTypes from 'prop-types';
import StarSMP from '../../components/StarSMP';
import CheckInFormItemLabel from './CheckInFormItemLabel';
import { ucFirst } from '../../utils/helpers';

const { Item } = Form;

const CheckinRateStars = ({ checkin }) => {
  const {
    text, value, key, disabled, rules,
  } = checkin;

  return (
    <div className={`check-in-page-content-form-rate checkin-rate-block ${!disabled ? 'active' : ''}`}>
      <Item
        rules={[...rules]}
        label={(
          <CheckInFormItemLabel
            text={`${text.split(' ').map((str) => ucFirst(str)).join(' ')}:`}
          />
        )}
        name={key}
      >
        <Rate
          readOnly={disabled}
          className="checkin-rate"
          count={7}
          value={value}
          character={<StarSMP className="checkin-rate" />}
        />
      </Item>
    </div>
  );
};

CheckinRateStars.propTypes = {
  checkin: PropTypes.shape({
    text: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    key: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    rules: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

export { CheckinRateStars };
