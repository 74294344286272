import React, { useEffect } from 'react';
import { Layout, PageHeader } from 'antd';
import { PlusCircleFilled } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from '../../utils/helpers';
import withSync from '../../hocks/withSync';
import withOnline from '../../hocks/withOnline';
import './_checkIn.less';
import { getUserCheckins } from '../../redux/asyncServices/checkinsService';
import { CheckIns } from './CheckIns';
import { getCheckins } from '../../redux/selectors/checkinsSelectors';
import { Routes } from '../../routes/routes';
import LoadingComponent from '../../components/LoadingComponent';
import withAuthBackground from '../../hocks/withAuthBackground';

const { Content } = Layout;

const CheckInsPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const checkins = useSelector(getCheckins);
  const isLoading = useSelector((state) => state.checkins.checkins.isLoading);
  useEffect(() => {
    dispatch(getUserCheckins());
  }, []);

  const handleAddCheckIn = () => history.push(Routes.CHECK_IN_ADD);

  const extra = (
    <div className="checkin-page-header-extra">
      Add New Entry
      <PlusCircleFilled
        className="checkin-page-header-extra-button"
        onClick={handleAddCheckIn}
      />
    </div>
  );

  if (isLoading) {
    return (
      <LoadingComponent />
    );
  }

  return (
    <Layout className="checkin-layout page__layout">
      <PageHeader
        className="page-header page_header_style_wrapper"
        title="CHECK INS"
        extra={extra}
      />
      <Content className="page__content__wrapper checkin-page-content-wrapper page_content_with_bg">
        <CheckIns
          checkins={checkins}
        />
      </Content>
    </Layout>

  );
};

export default compose(withAuthBackground, withOnline, withSync)(CheckInsPage);
