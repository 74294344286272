import React, { useEffect } from 'react';
import { withRouter, Redirect } from 'react-router';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from '../../utils/helpers';
import LoadingComponent from '../LoadingComponent';
import { checkAuth } from '../../redux/asyncServices/authService';

const ProtectedRoute = ({
  component: Component,
  path,
  location: { pathname },
  checkAuthD,
  isLoading,
  prevLocation,
  isAuth,
}) => {
  useEffect(() => {
    checkAuthD();
  }, [pathname]);

  const isRouterChange = () => prevLocation !== pathname;

  if (isLoading || isRouterChange()) {
    return (
      <LoadingComponent />
    );
  }

  return (
    <Route exact path={path}>
      {!isAuth ? <Redirect to="login" /> : <Component />}
    </Route>
  );
};

const mapStateToProps = ({
  auth:
    { isLoading, prevLocation, isAuth },
}) => ({ isLoading, prevLocation, isAuth });

const mapDispatchToProps = (dispatch, { location }) => ({
  checkAuthD: () => dispatch(checkAuth(location.pathname)),
});

ProtectedRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.elementType,
  ]),
  path: PropTypes.string.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isAuth: PropTypes.bool.isRequired,
  prevLocation: PropTypes.string.isRequired,
  checkAuthD: PropTypes.func.isRequired,
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ProtectedRoute);
