import React, { useState } from 'react';
import { Form, Radio, Space } from 'antd-mobile';
import PropTypes from 'prop-types';
import { validateAndPost } from '../postService';
import { useFormPropTypes } from '../../propTypes';
import ImgComponent from '../../../../components/ImgComponent';

const QuizRadio = ({
  id, question, name, customAttributes, form, plusProgress,
}) => {
  const isInitValidate = form.getFieldValue(name);
  const [isValidate, setIsValidate] = useState(!!isInitValidate);

  return (
    <Form.Item
      required
      className={isValidate ? 'validated' : 'not_validated'}
      key={id}
      label={question}
      name={name}
    >
      <Radio.Group
        onChange={async () => {
          const { data } = await validateAndPost({ form, name, id });
          if (data.statusCode === 200) {
            plusProgress(id);
            setIsValidate(true);
          }
        }}
      >
        <Space direction="vertical">
          {
              customAttributes.map((choice) => (
                <Radio
                  icon={(checked) => (checked
                    ? <ImgComponent src="./assets/images/Circle-Button-Active.svg" alt="Active" className="adm-radio-i" />
                    : <ImgComponent src="./assets/images/Star-button-no-star-Active.svg" alt=" " className="adm-radio-i" />)}
                  key={choice}
                  value={choice}
                >
                  {choice}
                </Radio>
              ))
            }
        </Space>
      </Radio.Group>

    </Form.Item>
  );
};

QuizRadio.propTypes = {
  plusProgress: PropTypes.func.isRequired,
  form: PropTypes.shape(useFormPropTypes),
  customAttributes: PropTypes.arrayOf(PropTypes.string).isRequired,
  id: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export { QuizRadio };
