import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../services/apiService';

export const getUnreadCountsFeedback = createAsyncThunk('UNREAD_FEEdBACKS', async () => {
  try {
    const { data } = await API.get('/feedbacks/unread-count');
    return data.payload;
  } catch (e) {
    console.log(e);
    return 0;
  }
});

export const getFeedBacks = createAsyncThunk('FEEDBACKS', async ({
  pageSize, page,
}) => {
  try {
    const { data } = await API.get('/feedbacks', {
      params: {
        page,
        pageSize,
      },
    });
    if (data.payload.feedbacks.length) {
      const [{ id }] = data.payload.feedbacks;
      await API.post(`/feedbacks/read-until/${id}`);
    }
    return data.payload;
  } catch (e) {
    return { feedbacks: [] };
  }
});

export const getMoreFeedBacks = createAsyncThunk('MORE_FEEDBACKS', async ({
  page,
  pageSize,
}) => {
  try {
    const { data } = await API.get('/feedbacks', {
      params: {
        page,
        pageSize,
      },
    });
    return data.payload;
  } catch (e) {
    return null;
  }
});
