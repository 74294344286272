import React from 'react';
import { Button } from 'antd-mobile';
import PropTypes from 'prop-types';
import { Upload } from 'antd';

const UploadButton = ({
  children, uploadProps = {}, buttonProps = {},
}) => (
  <Upload {...uploadProps}>
    <Button
      {...buttonProps}
    >
      {children}
    </Button>
  </Upload>
);

UploadButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
    PropTypes.elementType,
  ]).isRequired,
  uploadProps: PropTypes.objectOf(PropTypes.any),
  buttonProps: PropTypes.objectOf(PropTypes.any),
};

export default UploadButton;
