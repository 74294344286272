import React, { useEffect, useState } from 'react';
import { PageHeader } from 'antd';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd-mobile';
import { LeftCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import {
  INPUTS_FIELDS,
  RATES_FIELDS_STARS,
  SWITCH_FIELDS,
  FILE_FIELDS,
  RADIOS_FIELDS_EMOJI, createCheckin,
} from './checkinService';
import CheckinForm from './CheckinForm';
import withOnline from '../../hocks/withOnline';
import withSync from '../../hocks/withSync';
import { compose } from '../../utils/helpers';
import { errorColor, notifyDialog, successColor } from '../../utils/notify';
import TextWithLeftChildren from '../../components/TextWithLeftChildren';
import ImgComponent from '../../components/ImgComponent';
import withAuthBackground from '../../hocks/withAuthBackground';
import { authorizeMyFitnessPal, getAuthMyFitnessPal } from '../../redux/asyncServices/profileServices';
import { setMfpAccount } from '../../redux/reducers/profileReducer';

const CreateCheckin = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  // const mfpAccount = useSelector((state) => state.profile.mfpAccount);
  const [isShowMfp, setIsShowMfp] = useState(false);
  const [isLoadingMfp, setIsLoadingMfp] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const initialValues = [
    ...INPUTS_FIELDS,
    ...RATES_FIELDS_STARS,
    ...RADIOS_FIELDS_EMOJI,
    ...SWITCH_FIELDS,
    ...FILE_FIELDS,
  ].reduce((prev, { key, value }) => ({
    ...prev,
    [key]: value,
  }), {});
  const initialCsv = {
    averageCalories: null,
    averageProtein: null,
    averageFat: null,
    averageCarbohydrates: null,
    actualCalories: null,
    nutritionExport: null,
  };

  const onSubmitMfp = async ({ mfpLogin, mfpPassword }) => {
    try {
      setIsLoadingMfp(true);
      const response = await authorizeMyFitnessPal({ mfpLogin, mfpPassword });
      if (response.statusCode === 200) {
        dispatch(setMfpAccount(response.payload));
        setIsLoadingMfp(false);
        setIsShowMfp(false);
        return notifyDialog({
          title: 'Saved',
          titleColor: successColor,
        });
      }
    } catch (e) {
      setIsLoadingMfp(false);
      setIsShowMfp(false);
      return notifyDialog({
        title: e[0].mfp,
        titleColor: errorColor,
      });
    }
    setIsShowMfp(false);
    return setIsLoadingMfp(false);
  };

  const FooterButton = (disabled = null) => (
    <div className="form_item_button_wrapper">
      <Button
        type="submit"
        loading={isLoadingSubmit}
        className="form_button"
        disabled={disabled}
      >
        <TextWithLeftChildren
          className="text-with-image"
          text="Submit Check In"
        >
          <ImgComponent
            src="/assets/images/send-check-in.svg"
            alt="send-check-in-button"
            className="text-with-image-icon"
          />
        </TextWithLeftChildren>
      </Button>
    </div>
  );

  const onBack = ({ disabled }) => {
    if (!disabled) {
      history.goBack();
    }
  };

  const Header = (disabled) => (
    <PageHeader
      backIcon={<LeftCircleOutlined className="check-in-edit-header-back-icon" />}
      className="page-header page_header_style_wrapper check-in-edit-header"
      onBack={() => onBack(disabled)}
      title={(
        <div className="check-in-edit-header-title">
          {`New Checkin: ${moment().format('DD/MM/YY')}`.toUpperCase()}
        </div>
)}
    />
  );

  const onSubmit = async (values) => {
    setIsLoadingSubmit(true);

    // if (!mfpAccount.mfpLogin) {
    //   setIsLoadingSubmit(false);
    //   return notifyDialog({
    //     titleColor: errorColor,
    //     title: 'MFP credentials have not been set.
    //     Do you want to add them or proceed without nutrition data?',
    //     actions: [
    //       {
    //         key: 'close',
    //         text: 'Add MFP credentials',
    //         className: 'form_button',
    //         onClick: () => setIsShowMfp(true),
    //       },
    //       {
    //         key: 'accept',
    //         text: 'Submit checkin',
    //         onClick: async () => {
    //           const { statusCode } = await createCheckin(values);
    //           if (statusCode === 200) {
    //             setIsLoadingSubmit(false);
    //             history.push('/checkins');
    //             notifyDialog({
    //               titleColor: successColor,
    //               title: 'Success create',
    //             });
    //           }
    //         },
    //       },
    //     ],
    //   });
    // }
    const { statusCode } = await createCheckin(values);
    if (statusCode === 200) {
      setIsLoadingSubmit(false);
      history.push('/checkins');
      return notifyDialog({
        titleColor: successColor,
        title: 'Success create',
      });
    }
    return null;
  };

  useEffect(() => {
    dispatch(getAuthMyFitnessPal());
  }, []);

  return (
    <CheckinForm
      initialValues={initialValues}
      Header={Header}
      Footer={FooterButton}
      onSubmit={onSubmit}
      mfp={{
        isShowMfp,
        setIsShowMfp,
        isLoadingMfp,
        onSubmitMfp,
      }}
      data={{
        checkinInputs: [...INPUTS_FIELDS],
        checkinRate: [...RATES_FIELDS_STARS],
        checkinRateEmoji: [...RADIOS_FIELDS_EMOJI],
        checkinSwitch: [...SWITCH_FIELDS],
        checkinFile: [...FILE_FIELDS],
        initialCsv,
      }}
    />
  );
};

export default compose(withAuthBackground, withOnline, withSync)(CreateCheckin);
