import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../services/apiService';

export const getUserCheckins = createAsyncThunk('USER_CHECKINS',
  async () => {
    try {
      const { data } = await API.get('/checkins');
      return data.payload;
    } catch (e) {
      return null;
    }
  });

export const getCurrentCheckin = createAsyncThunk('USER_CHECKIN',
  async ({ id }) => {
    try {
      const { data } = await API.get(`/checkins/${id}`);
      return data.payload;
    } catch (e) {
      return null;
    }
  });
