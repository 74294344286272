import * as React from 'react';

function SidePhotoIcon(props) {
  return (
    <svg viewBox="0 0 45.55 50.29" width="1em" height="1em" {...props}>
      <defs>
        <style>{'.prefix__cls-1{fill:currentColor}'}</style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <g id="prefix__Layer_1-2" data-name="Layer 1">
          <path
            className="prefix__cls-1"
            d="M32.37 31.45a.8.8 0 00.18.14 1.69 1.69 0 001.12.47 1.69 1.69 0 001.17-.46l5.23-5.23a1.44 1.44 0 00.41-.59 1.7 1.7 0 000-1.31 1.3 1.3 0 00-.39-.58L35 18.69a1.76 1.76 0 00-2.45 0 1.74 1.74 0 000 2.45l2.21 2.22H18.13a1.75 1.75 0 100 3.5H34.7l-2.19 2.21a1.67 1.67 0 00-.14 2.38z"
          />
          <path
            className="prefix__cls-1"
            d="M13.18 18.81a1.14 1.14 0 00-.18-.15 1.73 1.73 0 00-1.12-.46h-.06a1.68 1.68 0 00-1.16.46l-5.23 5.23a1.38 1.38 0 00-.41.59 1.68 1.68 0 000 1.3 1.33 1.33 0 00.39.59l5.2 5.2a1.76 1.76 0 002.45 0 1.73 1.73 0 000-2.45l-2.2-2.23h16.56a1.75 1.75 0 000-3.49H10.85L13 21.19a1.68 1.68 0 00.18-2.38zM45.29 14A25.09 25.09 0 0011.61 2.63 22.71 22.71 0 00.66 13.15a1.74 1.74 0 00.77 2.34 1.78 1.78 0 001.34.08 1.71 1.71 0 001-.88c2.57-5.17 6.61-8.21 12.09-10.05a21.51 21.51 0 0116.52 1.13M29.66 45.65a21.53 21.53 0 01-16.53-1.12 21.77 21.77 0 01-9.73-9.71A1.75 1.75 0 00.1 36a1.7 1.7 0 00.16.33 25.13 25.13 0 0033.68 11.33"
          />
          <path
            className="prefix__cls-1"
            d="M15.89 4.64a21.66 21.66 0 0126.26 10.83 1.75 1.75 0 003.23.18 1.78 1.78 0 00.07-1.34 1.7 1.7 0 00-.16-.33A25.13 25.13 0 0011.61 2.63M.25 36.31a25.14 25.14 0 0045 0 1.75 1.75 0 00-.73-2.31 1.71 1.71 0 00-1.33-.09 1.74 1.74 0 00-1 .89 21.65 21.65 0 01-29 9.76"
          />
        </g>
      </g>
    </svg>
  );
}

const MemoSidePhotoIcon = React.memo(SidePhotoIcon);
export default MemoSidePhotoIcon;
