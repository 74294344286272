import React from 'react';
import { useSelector } from 'react-redux';

export default function withAuth(Component) {
  return function ComponentWithPermission(props) {
    const isAuth = useSelector((state) => state.auth.isAuth);

    if (isAuth) {
      return <Component {...props} />;
    }
    return null;
  };
}
