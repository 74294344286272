import React from 'react';
import { Form, Radio } from 'antd-mobile';
import PropTypes from 'prop-types';
import CheckInFormItemLabel from './CheckInFormItemLabel';
import { ucFirst } from '../../utils/helpers';

const { Item } = Form;

const CheckinRateSmiles = ({ checkin }) => {
  const {
    text, value, key, icons, disabled, rules,
  } = checkin;

  return (
    <div className={` checkin-rate-emoji check-in-page-content-form-input ${!disabled ? 'active' : ''}`}>
      <Item
        rules={[...rules]}
        label={(
          <CheckInFormItemLabel
            text={`${text.split(' ').map((str) => ucFirst(str)).join(' ')}:`}
          />
        )}
        name={key}
      >
        <Radio.Group
          className="checkin-rate"
          disabled={disabled}
          value={value}
        >
          {
              // eslint-disable-next-line no-shadow
                        icons.length > 0 && icons.map(({ value, icon }) => (
                          <Radio
                            key={value}
                            value={value}
                            icon={icon}
                            className="checkin-rate-icon"
                          />
                        ))
                    }
        </Radio.Group>
      </Item>
    </div>
  );
};

CheckinRateSmiles.propTypes = {
  checkin: PropTypes.shape({
    text: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    disabled: PropTypes.bool,
    key: PropTypes.string.isRequired,
    rules: PropTypes.arrayOf(PropTypes.object),
    icons: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.number.isRequired,
      icon: PropTypes.func.isRequired,
    })),
  }).isRequired,
};

export { CheckinRateSmiles };
