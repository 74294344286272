import React from 'react';
import { Form } from 'antd-mobile';
import PropTypes from 'prop-types';
import Input from '../../../../components/Fields/InputMob';
import { useFormPropTypes } from '../../propTypes';
import API from '../../../../services/apiService';

const getFeetIn = ({ form, name }) => {
  const feet = form.getFieldValue([name, 'feet']);
  const inches = form.getFieldValue([name, 'inches']);
  return `${Number(feet).toFixed(0) || ''}'${Number(inches).toFixed(1).replace(/\.0/, '') || 0}`;
};

const postFeetIn = async ({ form, name, id }) => {
  const feetInches = getFeetIn({ form, name });

  return API.post(`/questions/${id}/answer`, {
    userAnswer: feetInches,
    unitAnswer: 'feet_in',
  });
};

const QuizUnitFeetIn = ({
  name,
  form,
  id,
  plusProgress,
  minusProgress,
  setIsValidateQuestion,
  setIsRequiredError,
  isValidateUnits,
  setIsValidateUnits,
}) => (
  <>
    <Form.Item
      className={`${isValidateUnits.feet ? 'validated' : 'not_validated'}`}
      rules={[
        {
          validator: async (_, userAnswer) => {
            if (userAnswer >= 1 && userAnswer < 100) {
              const { data } = await postFeetIn({ form, name, id });
              if (data.statusCode === 200) {
                form.setFieldsValue({
                  [name]: {
                    cm: '',
                  },
                });
                setIsValidateUnits((state) => ({
                  ...state,
                  feet: true,
                  inches: true,
                  cm: false,
                }));
                setIsValidateQuestion(true);
                plusProgress(id);
                setIsRequiredError(false);
                return Promise.resolve();
              }
            }
            setIsValidateUnits(name, 'feet', false);
            const someUnit = Object.entries(form.getFieldValue(name))
              .reduce((prev, current) => [...prev, current[1]], []);
            if (!someUnit.some(Boolean)) {
              setIsValidateUnits((state) => ({
                ...state,
                feet: false,
                inches: false,
                cm: false,
              }));
              minusProgress(id);
              setIsValidateQuestion(false);
              return setIsRequiredError(true);
            }
            return setIsValidateUnits(name, 'feet', false);
          },
        },
      ]}
      validateTrigger="onBlur"
      name={[name, 'feet']}
    >
      <Input
        className="input-inches"
        placeholder="feet"
        type="number"
        bordered={false}
      />
    </Form.Item>
    <Form.Item
      className={`${isValidateUnits.inches ? 'validated' : 'not_validated'}`}
      validateTrigger="onBlur"
      rules={[
        {
          validator: async (_, userAnswer) => {
            if (userAnswer > 0 && userAnswer < 100) {
              const { data } = await postFeetIn({ form, name, id });
              if (data.statusCode === 200) {
                form.setFieldsValue({
                  [name]: {
                    cm: '',
                  },
                });
              }
              setIsValidateUnits((state) => ({
                ...state,
                inches: true,
                feet: true,
                cm: false,
              }));
              setIsValidateQuestion(true);
              plusProgress(id);
              setIsRequiredError(false);
              return Promise.resolve();
            }
            const someUnit = Object.entries(form.getFieldValue(name))
              .reduce((prev, current) => [...prev, current[1]], []);
            if (!someUnit.some(Boolean)) {
              minusProgress(id);
              setIsValidateQuestion(false);
              setIsValidateUnits((state) => ({
                ...state,
                inches: false,
                feet: false,
                cm: false,
              }));
              return setIsRequiredError(true);
            }
            return setIsValidateUnits((state) => ({
              ...state,
              inches: false,
            }));
          },
        },
      ]}
      name={[name, 'inches']}
    >
      <Input
        type="number"
        placeholder="inches"
        bordered={false}
      />
    </Form.Item>
  </>
);

QuizUnitFeetIn.propTypes = {
  isValidateUnits: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.string,
  ]),
  setIsValidateUnits: PropTypes.func.isRequired,
  setIsRequiredError: PropTypes.func.isRequired,
  setIsValidateQuestion: PropTypes.func.isRequired,
  plusProgress: PropTypes.func.isRequired,
  minusProgress: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  form: PropTypes.shape(useFormPropTypes),
  name: PropTypes.string.isRequired,
};

export { QuizUnitFeetIn };
