import { isOnlySpaces } from './helpers';
import { INVALID_FORMAT, PHONE_REGEX, THIS_FIELD_IS_REQUIRED } from '../CONSTANTS';

export const validationAntService = () => {
  const validatePassword = async (value) => {
    if (value) {
      const exp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;
      if (!exp.test(value)) {
        return Promise.reject(new Error(' Password must contain at least 8 symbols, 1 digit, 1 lowercase letter and 1 uppercase letter!'));
      }
      return Promise.resolve(value);
    }
    return Promise.reject(new Error(''));
  };

  const validateEmail = async (rule, value) => {
    if (!value) {
      return Promise.resolve(value);
    }
    // eslint-disable-next-line max-len
    const emailRegexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
    if (!emailRegexp.test(value)) {
      return Promise.reject(new Error('Invalid email format'));
    }
    return Promise.resolve(value);
  };
  const validateMaxLength = async (rule, value, maxLength) => {
    const MAX_LENGTH = maxLength || 80;
    if (value && value.length > MAX_LENGTH) {
      return Promise.reject(new Error(`Name too long. Max length is ${MAX_LENGTH} symbols`));
    }
    return Promise.resolve(value);
  };

  const validateFieldDuplicate = async (rule, value, allItems, uniqField, mode, currentItem) => {
    if (mode === 'add' || mode === 'clone') {
      const check = allItems.some((el) => el[uniqField] === value);
      if (check) {
        return Promise.reject(new Error(`Field duplicate!There is ${uniqField} with the same value`));
      }
      return Promise.resolve(value);
    }

    if (mode === 'edit') {
      const editAdditionalData = allItems.filter((el) => (String(el.id)
        ? el.id !== currentItem.id
        : el.local_id !== currentItem.local_id));

      const check = editAdditionalData.some((el) => el[uniqField] === value);

      if (check) {
        return Promise.reject(new Error(`Field duplicate!There is ${uniqField} with the same value`));
      }
      return Promise.resolve(value);
    }

    if (mode === 'move') {
      const editAdditionalData = allItems.filter((el) => el.id !== currentItem.id);
      const selectedCompany = editAdditionalData.find((el) => el[uniqField] === value);
      if (!selectedCompany) {
        return null;
      }
      const check = selectedCompany.scripts.some((el) => el[uniqField] === currentItem.name);

      if (check) {
        return Promise.reject(new Error(
          'Name duplicate!There is script with the same name in selected company.Please change script name',
        ));
      }
      return Promise.resolve(value);
    }
    return null;
  };

  const validateAlphabet = async (rule, value) => {
    if (value) {
      const regexp = /^[A-Za-z0-9!"#$%&'()*+,\-./:;<>=?@[\]{}\\^_`~\s|]+$/gm;
      if (!regexp.test(String(value).toLowerCase())) {
        return Promise.reject(new Error(
          'Available characters are latin alphabet, digits and special symbols',
        ));
      }
      return Promise.resolve(value);
    }
    return null;
  };

  const validateRequired = async (rule, value) => {
    if (!value || isOnlySpaces(value)) {
      return Promise.reject(new Error(THIS_FIELD_IS_REQUIRED));
    }
    return Promise.resolve(value);
  };

  const validateConfirmPassword = async (rule, value, compareValue) => {
    if (value !== compareValue || (compareValue && value !== compareValue)) {
      return Promise.reject(new Error('Passwords do not match'));
    }
    return Promise.resolve(value);
  };

  const validatePhoneNumber = async (_, value) => {
    if (!value) {
      return Promise.reject(THIS_FIELD_IS_REQUIRED);
    }
    if (!PHONE_REGEX.test(value)) {
      return Promise.reject(INVALID_FORMAT('phone number'));
    }
    return Promise.resolve();
  };

  return {
    validatePassword,
    validateEmail,
    validateFieldDuplicate,
    validateMaxLength,
    validateAlphabet,
    validateRequired,
    validatePhoneNumber,
    validateConfirmPassword,
  };
};
