import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from 'antd';
import { getAuthMyFitnessPal } from '../../redux/asyncServices/profileServices';
import LoadingComponent from '../../components/LoadingComponent';
import { ProfilePageMyFitnessPalForm } from './ProfilePageMyFitnessPalForm';

const ProfilePageMyFitnessPalWrapper = ({ onCancel, onFinish, isLoadingSubmit }) => {
  const dispatch = useDispatch();
  const mfpAccount = useSelector((state) => state.profile.mfpAccount);
  const isLoading = useSelector((state) => state.profile.isLoadingMfp);

  useEffect(() => {
    dispatch(getAuthMyFitnessPal());
  }, []);

  if (isLoading) {
    return (
      <LoadingComponent />
    );
  }

  return (
    <>
      <Typography.Title level={4} className="flex justify-center fitness-title">
        MyFitnessPal:
      </Typography.Title>
      <ProfilePageMyFitnessPalForm
        isLoading={isLoadingSubmit}
        mfpLogin={mfpAccount.mfpLogin}
        onCancel={onCancel}
        onFinish={onFinish}
      />
    </>
  );
};

ProfilePageMyFitnessPalWrapper.propTypes = {
  isLoadingSubmit: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
};

export { ProfilePageMyFitnessPalWrapper };
