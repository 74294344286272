export const getUnitsValue = (question) => {
  if (!question.answer) {
    return '';
  }
  if (question.answer?.unitAnswer !== 'feet_in' && question.answer?.unitAnswer !== null) {
    return {
      [question.answer.unitAnswer]: question.answer.userAnswer,
    };
  }
  if (question.answer?.unitAnswer === 'feet_in') {
    const splitFeetIn = question.answer.userAnswer.split('\'', 2);
    return {
      feet: splitFeetIn[0],
      inches: splitFeetIn[1],
    };
  }
  return '';
};
