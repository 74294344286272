import React from 'react';
import {
  Form, Input, Button, Checkbox, Layout,
} from 'antd';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
// import { validationAntService as validation } from '../../utils/validationAntService;
import 'antd/dist/antd.css';
import './_login.less';
// import moment from 'moment';
import { login } from '../../redux/asyncServices/authService';
import { compose, validateNoSpaces, validateRequired } from '../../utils/helpers';
import withoutAuthBackground from '../../hocks/withoutAuthBackground';
import { THIS_FIELD_IS_REQUIRED } from '../../CONSTANTS';

const { Content } = Layout;
// const { validatePassword, validateEmail } = validation();

const LoginPage = () => {
  const history = useHistory();
  const onFinish = async (userData) => login(userData, history);

  return (
    <>
      <Layout className="login-wrapper page__layout">
        <Content className="main">
          <div className="login__form">
            <div className="login__img flex justify-center">
              <img src="/assets/images/smp-logo.png" alt="logo" />
            </div>
            <div className="container-sm">
              <Form
                name="normal_login"
                className="login-form"
                initialValues={{ remember: true }}
                onFinish={onFinish}
              >
                <Form.Item
                  className="form_item_input_wrapper"
                  name="email"
                  validateTrigger={['onBlur']}
                  rules={[() => ({
                    async validator(_, value) {
                      await validateRequired(value);
                      await validateNoSpaces(value);
                      // await validateEmail(value)
                      return Promise.resolve(value);
                    },
                  }), { required: true, message: '' }]}
                >
                  <Input
                    className="form_input"
                    size="large"
                    placeholder="Email"
                  />
                </Form.Item>
                <Form.Item
                  className="form_item_input_wrapper"
                  name="password"
                  validateTrigger={['onBlur']}
                  rules={[
                    { required: true, message: THIS_FIELD_IS_REQUIRED },
                    // { validator: validatePassword },
                  ]}
                >
                  <Input.Password
                    className="form_input"
                    size="large"
                    type="password"
                    placeholder="Password"
                  />
                </Form.Item>
                <Form.Item className="login_form_auth_options form_item_checkbox_wrapper">
                  <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox className="form_checkbox">Remember me</Checkbox>
                  </Form.Item>
                  <Link to="/reset-password">Forgot your password?</Link>
                </Form.Item>
                <Form.Item className="form_item_button_wrapper">
                  <Button type="primary" htmlType="submit" size="large" className="form_button">
                    Log in
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </Content>
      </Layout>
      {/* <Layout.Footer className="footer footer-login">
      {`© ${moment().format('YYYY')} SMP Mobile`}
      </Layout.Footer> */}
    </>

  );
};

export default compose(withoutAuthBackground)(LoginPage);
