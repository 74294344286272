import React from 'react';
import { Form } from 'antd-mobile';
import PropTypes from 'prop-types';
import Input from '../../../../components/Fields/InputMob';
import { useFormPropTypes } from '../../propTypes';
import API from '../../../../services/apiService';

const QuizUnit = ({
  name,
  unit,
  form,
  id,
  plusProgress,
  minusProgress,
  setIsValidateQuestion,
  setIsRequiredError,
  isValidateUnits,
  setIsValidateUnits,
}) => (
  <Form.Item
    className={`${isValidateUnits[unit] ? 'validated' : 'not_validated'}`}
    name={[name, unit]}
    validateTrigger="onBlur"
    rules={[
      {
        validator: async (_, userAnswer) => {
          if (userAnswer > 0 && userAnswer < 9999) {
            const { data } = await API.post(`/questions/${id}/answer`, {
              userAnswer: parseFloat(Number(userAnswer).toFixed(2)),
              unitAnswer: unit,
            });
            if (data.statusCode === 200) {
              if (unit === 'kg') {
                form.setFieldsValue({
                  [name]: {
                    kg: parseFloat(Number(userAnswer).toFixed(2)),
                    lbs: '',
                  },
                });
                setIsValidateUnits((state) => ({
                  ...state,
                  kg: true,
                  lbs: false,
                }));
                setIsValidateQuestion(true);
                setIsRequiredError(false);
                plusProgress(id);
                return Promise.resolve();
              }
              form.setFieldsValue({
                [name]: {
                  [unit]: parseFloat(Number(userAnswer).toFixed(2)),
                  kg: '',
                  inches: '',
                  feet: '',
                },
              });
              setIsValidateUnits(() => ({
                [unit]: true,
                kg: false,
                inches: false,
                feet: false,
              }));
              setIsRequiredError(false);
              setIsValidateQuestion(true);
              plusProgress(id);
              return Promise.resolve();
            }
          }
          const someUnit = Object.entries(form.getFieldValue(name))
            .reduce((prev, current) => [...prev, current[1]], []);
          if (!someUnit.some(Boolean)) {
            minusProgress(id);
            setIsRequiredError(true);
            setIsValidateUnits((state) => ({
              ...state,
              [unit]: false,
              kg: false,
              inches: false,
              feet: false,
            }));
            return setIsValidateQuestion(false);
          }
          return setIsValidateUnits((state) => ({
            ...state,
            [unit]: false,
          }));
        },
      },
    ]}
  >
    <Input
      autoComplete="off"
      decimalSeparator="decimal"
      placeholder={unit}
      type="number"
      bordered={false}
    />

  </Form.Item>
);
QuizUnit.propTypes = {
  setIsValidateUnits: PropTypes.func.isRequired,
  isValidateUnits: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.string,
  ]),
  setIsRequiredError: PropTypes.func.isRequired,
  setIsValidateQuestion: PropTypes.func.isRequired,
  minusProgress: PropTypes.func.isRequired,
  plusProgress: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  form: PropTypes.shape(useFormPropTypes),
  unit: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export { QuizUnit };
