import React, { memo } from 'react';
import {
  Avatar, Comment,
  Typography,
} from 'antd';
import PropTypes from 'prop-types';
import { LoadingOutlined, CheckOutlined } from '@ant-design/icons';
import { Popover } from 'antd-mobile';
import { userPropTypes } from './propTypes';
import { getAuthorName, getAvatarName } from './service';
import { getDateMessage } from '../../utils/helpers';

const { Paragraph } = Typography;

const Message = ({
  notes, date, user, Author, feedbackFromId, User, isLoadingMessage = false, usersSeen,
}) => {
  const actions = () => (usersSeen.length ? usersSeen.map((userSeen) => ({
    key: userSeen.userName,
    text: `${userSeen.userId === user.id ? 'Me' : userSeen.userName}: ${getDateMessage(userSeen.date)}`,
  })) : null);

  return (
    <Comment
      className={`feedback_comment ${user.id === Author?.id ? 'feedback_comment_user' : 'feedback_comment_coach'}`}
      avatar={user.id !== Author?.id
          && (
          <Avatar>
            {getAvatarName({ Author, feedbackFromId, User })}
          </Avatar>
          )}
      author={user.id === Author?.id ? null : getAuthorName({ Author, feedbackFromId, User })}
      datetime={user.id === Author?.id ? null : (
        <div>
          <span>{date}</span>
          {!isLoadingMessage
            && usersSeen.length ? (
              <Popover.Menu
                actions={actions()}
                placement="bottom"
                trigger="click"
              >
                <CheckOutlined style={{ fontSize: 16, marginLeft: 5, color: '#ead475' }} />
              </Popover.Menu>
            ) : <CheckOutlined style={{ fontSize: 16, marginLeft: 5, color: '#ad974e' }} />}
          {isLoadingMessage && <LoadingOutlined className="feedback_loading_icon" />}
        </div>
      )}
      content={(
        <Paragraph>
          {user.id === Author?.id && (
          <div className="feedback_date_message">
            <span style={{ fontSize: 12 }} className="ant-comment-content-author-time">
              {date}
            </span>
              {!isLoadingMessage
                      && usersSeen.length ? (
                        <Popover.Menu
                          actions={actions()}
                          placement="bottom"
                          trigger="click"
                        >
                          <CheckOutlined style={{ fontSize: 16, marginLeft: 5, color: '#ead475' }} />
                        </Popover.Menu>
                ) : <CheckOutlined style={{ fontSize: 16, marginLeft: 5, color: '#ad974e' }} />}
              {isLoadingMessage && <LoadingOutlined className="feedback_loading_icon" />}
            <br />
          </div>
          )}
          {notes}
        </Paragraph>
)}
    />
  );
};

Message.propTypes = {
  id: PropTypes.string,
  notes: PropTypes.string,
  date: PropTypes.string,
  isLoadingMessage: PropTypes.bool,
  usersSeen: PropTypes.arrayOf(PropTypes.shape({
    feedbackId: PropTypes.string,
    userId: PropTypes.string,
    chatUserId: PropTypes.string,
    date: PropTypes.string,
    userName: PropTypes.string,
  })),
  // userId: PropTypes.string,
  feedbackFromId: PropTypes.string,
  Author: PropTypes.shape(userPropTypes),
  User: PropTypes.shape(userPropTypes),
  user: PropTypes.shape(userPropTypes),
};

export default memo(Message);
