import { QuizRadio } from '../Form/QuizRadio/QuizRadio';

export const choiceService = (question) => {
  if (!question.answerTemplate) {
    // console.log('without template with custom CHOICE', question);
    return {
      ...question,
      value: question.answer ? question.answer.userAnswer : '',
      name: question.prompt.split(' ').join(''),
      customAttributes: JSON.parse(question.customAttributes).choices,
      ViewComponent: QuizRadio,
    };
  }
  return question;
};
