import React from 'react';
import { Image, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import { LOAD_FAILED_IMAGE } from '../utils/vars';

const ImageWithSkeleton = ({
  className, loading, data, alt, preview, size,
}) => (
  <Skeleton
    className={`${className}-skeleton`}
    loading={loading}
    avatar={{ active: true, shape: 'square', size }}
    paragraph={false}
    title={false}
    active
  >
    <Image
      className={`${className}-image`}
      src={data || ''}
      width="100%"
      preview={preview}
      alt={alt}
      fallback={LOAD_FAILED_IMAGE}
    />
  </Skeleton>
);

ImageWithSkeleton.propTypes = {
  className: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
  preview: PropTypes.bool.isRequired,
  size: PropTypes.number,
};

export default ImageWithSkeleton;
