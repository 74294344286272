import React, { useEffect, useState } from 'react';
import { Form } from 'antd-mobile';
import PropTypes from 'prop-types';
import TextArea from '../../../../components/Fields/TextArea';
import { validateAndPost } from '../postService';
import { answerPropTypes, useFormPropTypes } from '../../propTypes';
import { THIS_FIELD_IS_REQUIRED } from '../../../../CONSTANTS';

const QuizTextArea = ({
  id, question, name, form, plusProgress, minusProgress, answer, answerTemplate,
}) => {
  const [isValidate, setIsValidate] = useState(false);
  useEffect(() => {
    if (answerTemplate === form.getFieldValue(name)) {
      setIsValidate(false);
    }
    if (answer?.userAnswer) {
      setIsValidate(true);
    }
  }, []);

  return (
    <Form.Item
      required
      key={id}
      label={question}
      className={isValidate ? 'validated' : 'not_validated'}
      name={name}
      validateTrigger="onBlur"
      rules={[
        {
          required: true,
          message: THIS_FIELD_IS_REQUIRED,
        },
        {
          validator: async (_, userAnswer) => {
            if (answerTemplate && userAnswer.replace(/\s/g, '') === answerTemplate.replace(/\s/g, '')) {
              minusProgress(id);
              return Promise.reject();
            }
            if (userAnswer.trim()) {
              const { data } = await validateAndPost({ id, name, form });
              if (data.statusCode === 200) {
                setIsValidate(true);
                plusProgress(id);
                return Promise.resolve();
              }
            }
            setIsValidate(false);
            minusProgress(id);
            return Promise.reject();
          },
          message: THIS_FIELD_IS_REQUIRED,
        },
      ]}
    >
      <TextArea
        autoSize={{
          minRows: 7,
        }}
      />
    </Form.Item>
  );
};

QuizTextArea.propTypes = {
  answer: PropTypes.shape(answerPropTypes),
  answerTemplate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
  plusProgress: PropTypes.func.isRequired,
  minusProgress: PropTypes.func.isRequired,
  form: PropTypes.shape(useFormPropTypes),
  id: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export { QuizTextArea };
