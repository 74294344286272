import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { Button } from 'antd-mobile';
import { validationAntService as validation } from '../../utils/validationAntService';
import InputPassword from '../../components/Fields/InputPassword';

const { validatePassword, validateConfirmPassword, validateRequired } = validation();

const EditProfileForm = ({
  values = {}, onCancel, onFinish,
}) => (
  <Form
    className="edit_profile_form"
    onFinish={onFinish}
    initialValues={{ ...values }}
  >
    <Form.Item
      validateTrigger="onBlur"
      className="form_item_input_wrapper"
      name="oldPassword"
      rules={[
        { validator: (rule, value) => validateRequired(rule, value) },
      ]}
    >
      <InputPassword
        className="form_input"
        size="large"
        placeholder="Enter Old Password"
      />
    </Form.Item>
    <Form.Item
      validateTrigger="onBlur"
      className="form_item_input_wrapper"
      name="password"
      rules={[() => ({
        validator: async (rule, value) => {
          await validateRequired(rule, value);
          await validatePassword(value);
          return Promise.resolve(value);
        },
      })]}
      // rules={[
      //   { validator: (rule, value) => validateRequired(rule, value) },
      //   { validator: (rule, value) => validatePassword(rule, value) },
      // ]}
    >
      <InputPassword
        className="form_input"
        size="large"
        placeholder="Enter New Password"
      />
    </Form.Item>
    <Form.Item
      validateTrigger="onBlur"
      className="form_item_input_wrapper"
      name="confirmPassword"
      rules={[
        ({ getFieldValue }) => (
          { validator: (rule, value) => validateConfirmPassword(rule, value, getFieldValue('password')) }
        ),
      ]}
    >
      <InputPassword
        className="form_input"
        size="large"
        placeholder="Confirm New Password"
      />
    </Form.Item>
    <Form.Item className="form_item_button_wrapper">
      <Button
        onClick={onCancel}
        className="form_button"
      >
        Cancel
      </Button>
    </Form.Item>
    <Form.Item className="form_item_button_wrapper">
      <Button
        type="primary"
        htmlType="submit"
        className="form_button"
      >
        Save
      </Button>
    </Form.Item>
  </Form>
);

EditProfileForm.propTypes = {
  values: PropTypes.shape({
    password: PropTypes.string,
    oldPassword: PropTypes.string,
    confirmPassword: PropTypes.string,
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
};

export { EditProfileForm };
