import { createSelector } from '@reduxjs/toolkit';

const checkinsSelector = (state) => state.checkins.checkins.checkins;
const checkinSelector = (state) => state.checkins.checkin.checkin;
const parsedCsvDataSelector = (state) => state.checkins.parsedCsvData;
const mfpSelector = (state) => state.profile.mfpAccount?.mfpLogin;

export const getMfpLogin = createSelector([mfpSelector], (mfp) => mfp);

export const getCheckins = createSelector([checkinsSelector],
  (checkins) => [...checkins].sort((a, b) => (new Date(a.date) < new Date(b.date) ? 1 : -1)));

export const getCheckin = createSelector([checkinSelector],
  (checkin) => checkin);

export const getParsedCsvData = createSelector([parsedCsvDataSelector],
  (parsedCsvData) => parsedCsvData);
