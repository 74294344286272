import React from 'react';
import PropTypes from 'prop-types';

export default function HeaderInfo({ headerInfoData = [] }) {
  return (
    <div className="header_version_space">
      {headerInfoData.map(({ text, value }) => {
        if (value) {
          return (
            <span className="header_version_space-item" key={text}>
              {`${text}: ${value}`}
            </span>
          );
        }
        return null;
      })}
    </div>
  );
}

HeaderInfo.propTypes = {
  headerInfoData: PropTypes.arrayOf(PropTypes.object).isRequired,
};
