import React from 'react';
import { Checkbox } from 'antd';
import { Form } from 'antd-mobile';
import PropTypes from 'prop-types';
import CheckInFormItemLabel from './CheckInFormItemLabel';

const { Item } = Form;

const CheckinCheckboxItem = ({ checkin }) => {
  const { key, text, disabled } = checkin;
  return (
    <div className={`check-in-page-content-form-input form_item_checkbox_wrapper ${!disabled ? 'active' : ''}`}>
      <Item
        valuePropName="checked"
        name={key}
        label={(
          <CheckInFormItemLabel
            text={`${text}`}
          />
        )}
      >
        <Checkbox className="form_checkbox" disabled={disabled} />
      </Item>
    </div>
  );
};

CheckinCheckboxItem.propTypes = {
  checkin: PropTypes.shape({
    key: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
  }).isRequired,
};

export { CheckinCheckboxItem };
