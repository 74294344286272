import { createSlice } from '@reduxjs/toolkit';
import { getAuthMyFitnessPal, getUserProfile } from '../asyncServices/profileServices';

const profileSlice = createSlice({
  name: 'profileReducer',
  initialState: {
    isLoading: true,
    isLoadingMfp: true,
    mfpAccount: null,
    user: {},
  },
  reducers: {
    setMfpAccount: (state, { payload }) => {
      state.mfpAccount = payload;
    },
    setUpdateUser: (state, { payload }) => {
      state.user = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserProfile.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUserProfile.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.user = payload;
    });
    builder.addCase(getAuthMyFitnessPal.pending, (state) => {
      state.isLoadingMfp = true;
    });
    builder.addCase(getAuthMyFitnessPal.fulfilled, (state, { payload }) => {
      state.mfpAccount = payload;
      state.isLoadingMfp = false;
    });
  },
});

export const { setUpdateUser, setMfpAccount } = profileSlice.actions;

export default profileSlice.reducer;
