import React, {
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Badge } from 'antd-mobile';
import { PageHeader, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { FETCH_FEEDBACKS_DELAY, FETCH_FEEDBACKS_PAGE_SIZE } from '../../utils/vars';
import {
  compose, getLastOnlineDate, getOnlineStatus,
} from '../../utils/helpers';
import withOnline from '../../hocks/withOnline';
import withSync from '../../hocks/withSync';
import './_feedback.less';
import { getFeedBacks, getMoreFeedBacks } from '../../redux/asyncServices/feedBackService';
import LoadingComponent from '../../components/LoadingComponent';
import { getFeedBacksSelector } from '../../redux/selectors/feedBacksSelector';
import { InputMessage } from './InputMessage';
import { LazyScroll } from './LazyScroll';
import Message from './Message';
import withScroll from '../../hocks/withScroll';
import withAuthBackground from '../../hocks/withAuthBackground';

const FeedBackPage = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.feedBacks.isLoading);
  const totalCount = useSelector((state) => state.feedBacks.totalCount);
  const user = useSelector((state) => state.auth.user);
  const [currentPage, setCurrentPage] = useState(1);
  const { feedBacks } = useSelector(getFeedBacksSelector);

  // const actionsMenu = [
  //   { key: 'read', icon: <EyeOutlined />, text: 'Mark as read' },
  //   { key: 'unread', icon: <EyeInvisibleOutlined />, text: 'Mark as unread' },
  // ];

  const loadMore = () => {
    setTimeout(() => {
      dispatch(getMoreFeedBacks({
        page: currentPage + 1,
        pageSize: FETCH_FEEDBACKS_PAGE_SIZE,
      }));
    }, 100);
    setCurrentPage(currentPage + 1);
  };

  useEffect(() => {
    const getIntervalFeedbacks = setInterval(() => {
      dispatch(getFeedBacks({
        page: 1,
        pageSize: FETCH_FEEDBACKS_PAGE_SIZE,
      }));
    }, FETCH_FEEDBACKS_DELAY);

    return () => {
      clearInterval(getIntervalFeedbacks);
    };
  }, []);

  // useEffect(() => {
  //   dispatch(getFeedBacks({
  //     page: 1,
  //     pageSize: FETCH_FEEDBACKS_PAGE_SIZE,
  //   }));
  // }, []);

  if (isLoading) {
    return <LoadingComponent />;
  }

  return (
    <>
      <PageHeader
        className="feedback_page_header"
        subTitle={getLastOnlineDate(user.Coach?.lastOnline)}
        title={user.Coach?.fullName || 'Unknown'}
        extra={[
          <Badge
            color="#87d068"
            content={getOnlineStatus(user.Coach?.lastOnline)}
            style={{ '--right': '100%' }}
          >
            <Avatar dot key={user.id} shape icon={<UserOutlined />} size={48} />
          </Badge>,
        ]}
      />
      <LazyScroll
        totalCount={totalCount}
        fetch={loadMore}
        data={feedBacks}
      >
        {
            feedBacks.length > 0
            && feedBacks.map((feedback) => <Message key={feedback.id} {...feedback} user={user} />)
        }
      </LazyScroll>
      <InputMessage user={user} />
    </>
  );
};

export default compose(withAuthBackground, withScroll, withOnline, withSync)(FeedBackPage);
