import React, { useState } from 'react';
import LoadingComponent from '../components/LoadingComponent';

const withLoadingSpinner = (Component) => (props) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      {isLoading && <div className="loading_spinner"><LoadingComponent /></div>}
      <Component
        {...props}
        setIsLoadingSpinner={setIsLoading}
      />
    </>
  );
};

export default withLoadingSpinner;
