import React, { useState } from 'react';
import {
  Form, Input, Button, Layout,
} from 'antd';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './_passwordReset.less';
import 'antd/dist/antd.css';
import { useHistory } from 'react-router';
import { errorColor, notifyDialog, successColor } from '../../utils/notify';
import LoadingComponent from '../../components/LoadingComponent';
import { compose } from '../../utils/helpers';
import withoutAuthBackground from '../../hocks/withoutAuthBackground';
import { THIS_FIELD_IS_REQUIRED } from '../../CONSTANTS';

const { Content } = Layout;

const PasswordResetPage = () => {
  const history = useHistory();
  const [isRedirectSpinnerVisible, setRedirectSpinnerVisible] = useState(false);

  const getContext = () => {
    const context = window.location.pathname.split('/reset-password')[0];
    const host = window.location.origin;
    return host + context;
  };

  const onFinish = async ({ email }) => {
    try {
      await axios.post('/api/auth/reset-password', {
        email,
        href: `${getContext()}/reset-password/confirm?token=`,
      });
      notifyDialog({
        title: `Reset password link has been sent to ${email}`,
        titleColor: successColor,
      });
      setRedirectSpinnerVisible(true);
      setTimeout(() => {
        history.push('/login');
      }, 3000);
    } catch (err) {
      if (err.response.status === 404) {
        notifyDialog({
          title: 'User not found',
          titleColor: errorColor,
        });
      } else {
        notifyDialog({
          title: err.response.data.message,
          titleColor: errorColor,
        });
      }
    }
  };

  if (isRedirectSpinnerVisible) {
    return (
      <LoadingComponent />
    );
  }

  return (
    <>
      <Layout className="login-wrapper page__layout">
        <Content className="main page_content_with_bg">
          <div className="login__form">
            <div className="login__img flex justify-center">
              <img src="/assets/images/smp-logo.png" alt="logo" />
            </div>
            <div className="container-sm">
              <Form name="reset-password" className="login-form" onFinish={onFinish} initialValues={{ remember: true }}>
                <Form.Item
                  className="form_item_input_wrapper"
                  name="email"
                  validateTrigger={['onBlur']}
                  rules={[
                    { required: true, message: THIS_FIELD_IS_REQUIRED },
                  ]}
                >
                  <Input
                    className="form_input"
                    size="large"
                    placeholder="Email"
                  />
                </Form.Item>
                <Form.Item className="form_item_button_wrapper">
                  <Button htmlType="submit" className="form_button" block>
                    Send login link
                  </Button>
                </Form.Item>
                <Form.Item className="form_item_button_wrapper">
                  <Button className="form_button" block>
                    <Link to="/login">Back to login</Link>
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </Content>
      </Layout>
      {/* <Layout.Footer className="footer footer-login">
      {`© ${moment().format('YYYY')} SMP Mobile`}
      </Layout.Footer> */}
    </>
  );
};

export default compose(withoutAuthBackground)(PasswordResetPage);
