import React, { useEffect, useRef } from 'react';
import { Layout, PageHeader } from 'antd';
import { Form, ProgressBar } from 'antd-mobile';
import { useDispatch, useSelector } from 'react-redux';
import withAuth from '../../hocks/withAuth';
import { compose } from '../../utils/helpers';
import withSync from '../../hocks/withSync';
import { getQuizQuestions } from '../../redux/asyncServices/quizServices';
import { getProgress, getQuestions } from '../../redux/selectors/quizSelectors';
import LoadingComponent from '../../components/LoadingComponent';
import './_quizPage.less';
import { isLoadingAction, minusAnswer, plusAnswer } from '../../redux/reducers/quizReducer';
import withAuthBackground from '../../hocks/withAuthBackground';

const { Content } = Layout;

const QuizPage = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.quiz.isLoading);
  const progressPercent = useSelector(getProgress);
  const ref = useRef(null);
  const {
    questionsView,
    initialValues,
  } = useSelector(getQuestions);
  const [form] = Form.useForm();

  const plusProgress = (id) => dispatch(plusAnswer(id));

  const minusProgress = (id) => dispatch(minusAnswer(id));

  useEffect(() => {
    dispatch(getQuizQuestions());

    // window.addEventListener('resize', () => {
    //   alert(ref.current.clientHeight);
    // });

    return () => {
      dispatch(isLoadingAction());
    };
  }, [ref]);

  if (isLoading) {
    return <LoadingComponent />;
  }

  return (
    <div
      ref={ref}
    >
      <Layout
        className="page__layout quiz_page"
      >
        <ProgressBar
          percent={progressPercent}
        />
        <PageHeader
          className="page-header page_header_style_wrapper"
          title="Questionaire"
        />
        <Content>
          <Form
            form={form}
            initialValues={initialValues}
          >
            {
                questionsView.length > 0 && questionsView.map((question) => {
                  const { ViewComponent, ...rest } = question;
                  return (
                    <ViewComponent
                      plusProgress={plusProgress}
                      minusProgress={minusProgress}
                      form={form}
                      key={question.id}
                      {...rest}
                    />
                  );
                })
              }
          </Form>
        </Content>
      </Layout>
    </div>
  );
};

export default compose(withAuthBackground, withAuth, withSync)(QuizPage);
