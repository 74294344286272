import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { me, isOnline, Logger } from '../../utils/helpers';
import { errorColor, notifyDialog } from '../../utils/notify';
import db from '../../indexedDB/db';
import API from '../../services/apiService';

const logger = Logger('authService.js');

export const checkAuth = createAsyncThunk('checkAuth', async (prevLocation) => {
  const online = await isOnline();

  if (online) {
    logger.debug('checkAuth. online status:', online);

    const { user, statusCode } = await me();
    logger.debug('checkAuth. User and status code:', { user, statusCode });
    if (user) {
      await db.loggedUser.clear();
      await db.loggedUser.add(user);
      return { user, prevLocation, isAuth: true };
    }
    logger.debug('checkAuth. errorStatusCode:', statusCode);
    if (statusCode === 401) {
      await db.loggedUser.clear();
      window.location.href = `${process.env.REACT_APP_PUBLIC_URL}/login`;
    } else if (statusCode === 500) {
      notifyDialog({
        title: 'Server error',
        titleColor: errorColor,
      });
    } else {
      notifyDialog({
        title: 'Something went wrong',
        titleColor: errorColor,
      });
    }
  } else {
    const user = await db.loggedUser.orderBy('id').last();
    logger.debug('checkAuth. User body in offline mode:', user);
    if (!user) {
      return { user: null, prevLocation, isAuth: false };
    }
    return { user, prevLocation, isAuth: true };
  }
  return null;
});

export const login = async ({ email, password }, history) => {
  // const online = await isOnline();
  // if (online) {
  try {
    await axios.post('/api/auth/login', { email, password });
    return history.push('/welcome');
  } catch (error) {
    if (error.response.status === 401) {
      return notifyDialog({
        title: 'Incorrect email or password',
        titleColor: errorColor,
      });
    } if (error.response.status === 500) {
      return notifyDialog({
        title: 'Server error',
        titleColor: errorColor,
      });
    }
    if (error.response.data.message) {
      return notifyDialog({
        title: error.response?.data.message,
        titleColor: errorColor,
      });
    }
    return notifyDialog({
      title: 'No internet connection',
      titleColor: errorColor,
    });
  }
  // }
  // return notifyDialog({
  //   title: 'No internet connection',
  //   titleColor: errorColor,
  // });
};

export const logout = () => async (dispatch) => {
  if (await isOnline) {
    await API.post('auth/logout');
    dispatch({ type: 'LOG_OUT' });
    await db.loggedUser.clear();
  } else {
    return notifyDialog({
      title: 'No internet connection',
      titleColor: errorColor,
    });
  }
  return null;
};
