import { combineReducers } from '@reduxjs/toolkit';
import commonReducer from './commonReducer';
import authReducer from './authReducer';
import profileReducer from './profileReducer';
import checkinsReducer from './checkinsReducer';
import quizReducer from './quizReducer';
import feedBackReducer from './feedBackReducer';

const appReducer = combineReducers({
  auth: authReducer,
  common: commonReducer,
  profile: profileReducer,
  checkins: checkinsReducer,
  quiz: quizReducer,
  feedBacks: feedBackReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOG_OUT') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
