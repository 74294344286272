import { Dialog } from 'antd-mobile';
import React from 'react';

const notifyDialog = ({ titleColor, ...props }) => {
  if (titleColor) {
    props.title = (<span style={{ color: titleColor }}>{props.title}</span>);
  }

  if (Array.isArray(props.actions)) {
    props.actions = props.actions.map((action) => ({ ...action, className: 'dialog_action_button' }));
  }
  return Dialog.show({
    closeOnMaskClick: true,
    closeOnAction: true,
    maskClassName: 'dialog_mask',
    ...props,
  });
};

const errorColor = '#ffa180';
const successColor = '#667d61';

export { notifyDialog, errorColor, successColor };
