import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../services/apiService';

export const getQuizQuestions = createAsyncThunk('QUIZ_QUESTIONS',
  async () => {
    try {
      const { data } = await API.get('/questions');
      return data.payload;
    } catch (e) {
      return null;
    }
  });
