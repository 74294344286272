import React from 'react';
import { Form } from 'antd-mobile';
import PropTypes from 'prop-types';
import Input from '../../components/Fields/InputMob';
import CheckInFormItemLabel from './CheckInFormItemLabel';
import { ucFirst } from '../../utils/helpers';

const { Item } = Form;

const CheckinInputItem = ({ checkin }) => {
  const {
    unit, text, key, disabled, rules, type, placeholder,
  } = checkin;

  return (
    <div className={`check-in-page-content-form-input  ${!disabled ? 'active' : ''}`}>
      <Item
        validateTrigger="onBlur"
        label={(
          <CheckInFormItemLabel
            text={`${text.split(' ').map((str) => ucFirst(str)).join(' ')} (${unit.endsWith('s') ? unit.slice(0, -1) : unit}):`}
          />
)}
        rules={[...rules]}
        name={key}
      >
        <Input
          onClick={(e) => e.preventDefault()}
          bordered={false}
          type={type}
          autoComplete="off"
          decimalSeparator="decimal"
          placeholder={placeholder}
          disabled={disabled}
        />
      </Item>
    </div>
  );
};

CheckinInputItem.propTypes = {
  checkin: PropTypes.shape({
    unit: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    placeholder: PropTypes.number.isRequired,
    disabled: PropTypes.bool.isRequired,
    rules: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

export { CheckinInputItem };
