import { QuizTextArea } from '../Form/QuizTextarea/QuizTextArea';

const getTextValue = (question) => {
  if (question.answerTemplate && !question.answer) {
    return question.answerTemplate;
  }
  if (question.answer) {
    return question.answer.userAnswer;
  }
  return '';
};

export const textareaService = (question) => ({
  ...question,
  name: question.prompt.split(' ').join(''),
  value: getTextValue(question),
  ViewComponent: QuizTextArea,
});
