import { useEffect, useState } from 'react';

export default function useOrientationChange() {
  const [isLandscape, setIsLandscape] = useState(false);
  useEffect(() => {
    const { body } = document;
    function deviceOrientation() {
      body.classList = '';
      switch (window.orientation) {
        case 90:
        case -90:
          body.classList = 'body_black';
          setIsLandscape(true);
          break;
        default:
          setIsLandscape(false);
          break;
      }
    }
    if (window.orientation === 90 || window.orientation === -90) {
      body.classList = 'body_black';
      setIsLandscape(true);
    }

    window.addEventListener('orientationchange', deviceOrientation);
    return () => window.removeEventListener('orientationchange', deviceOrientation);
  }, []);
  return { isLandscape };
}
