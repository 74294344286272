import React from 'react';
import {
  Avatar, Col, Image, Skeleton,
} from 'antd';
import PropTypes from 'prop-types';
import { CloseCircleOutline } from 'antd-mobile-icons';

const ProfilePhoto = ({
  src, isLoadingSkeleton, image, newImage, firstName, lastName, onRemovePhoto, isShowEdit,
}) => (
  <Col span={12} className="flex justify-center">
    {src || image || newImage ? (
      <Skeleton
        paragraph={false}
        title={false}
        className="profile-logo-skeleton"
        loading={isLoadingSkeleton}
        avatar={{
          shape: 'circle',
          active: true,
          size: 'default',
        }}
        active
      >
        <Image
          preview={false}
          className="profile-logo"
          src={newImage || image}
        />
        {isShowEdit
          && (
          <CloseCircleOutline
            style={{ position: 'absolute', right: 0 }}
            onClick={onRemovePhoto}
            color="red"
          />
          )}
      </Skeleton>
    ) : (
      <Avatar
        className="profile-logo"
      >
        {firstName[0].toUpperCase() + lastName[0].toUpperCase()}
      </Avatar>
    )}
  </Col>
);

ProfilePhoto.propTypes = {
  src: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.string]),
  isLoadingSkeleton: PropTypes.bool.isRequired,
  onRemovePhoto: PropTypes.func.isRequired,
  isShowEdit: PropTypes.bool.isRequired,
  image: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.string]),
  newImage: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.string]),
  lastName: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
};

export { ProfilePhoto };
